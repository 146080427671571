import React from "react";
import Hero from "../PageComponent/Home/Hero";
import Title from "../PageComponent/Home/Title";
import Milestone from "../PageComponent/Home/Milestone"
import Partnership from "../PageComponent/Home/Partnership";
import Tokenomic from "../PageComponent/Home/Tokenomic";
import Roadmap from "../PageComponent/Home/Roadmap";
import Auditby from "../PageComponent/Home/Auditby";
import Community from "../PageComponent/Home/Community";
import Footer from "../Component/Footer/Footer";
import BG1 from "../asset/BG1.png";
import BG2 from "../asset/BG2.png";

const Home = () => {
  

  return (
    <div className="Home 2xl:mt-[80px] xl:mt-[80px] lg:mt-[80px] mt-[60px] w-full flex ">
       <img
        className="absolute w-full z-0  2xl:translate-y-[700px] xl:translate-y-[700px] lg:translate-y-[700px] translate-y-[220px]"
        src={BG1}
        alt=""
      />
      <img
        className="absolute w-full z-0  2xl:translate-y-[2700px] xl:translate-y-[2700px] lg:translate-y-[2700px] translate-y-[2350px]"
        src={BG2}
        alt=""
      />
     

      <div className="wrapperhome relative w-full flex flex-col items-center">
        <Hero />
        <Title />
        <Milestone/>
        <Partnership/>
        <Tokenomic />
        <Roadmap />
        <Auditby />
        <Community />
        <Footer />
      </div>
     
    </div>
    
    
  );
};

export default Home;
