import React, { useEffect } from "react";
import Paper from "../../asset/paper 1.png";
import "./Title.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Title = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  // const handleButtonClick = () => {
  //   console.log("Attempting to open PDF at /Whitepaper_english.pdf");
  //   const win = window.open("/whitepaper.pdf", "_blank");
  //   if (!win) {
  //     alert("Popup was blocked!");
  //   } else {
  //     win.focus();
  //   }
  // };

  return (
    <section
      data-aos="fade-up"
      data-aos-delay="100"
      className="title w-[90%] 2xl:w-[60%] justify-center flex 2xl:mt-[100px] xl:-mt[70px] lg:-[70px] mt-[20px]"
    >
      <div className="wrappertitle w-full justify-center flex flex-col items-center gap-8">
        <p className="txt text-white font-black flex flex-wrap text-center 2xl:text-[60px] xl:text-[60px] lg:text-[60px] text-[28px]">
          Let's create a new history The creative industry ecosystem
        </p>
        <p className="text-white font-regular flex flex-wrap text-center 2xl:text-[24px] xl:text-[30px] lg:text-[20px] text-[12px]">
          XYZMER is more than just a platform, it is a revolution in the world
          of creative art that brings transparency, participation, and
          innovation to every aspect of the industry. By joining XYZMER, you are
          not just investing in a business but also in the future of a fair and
          sustainable creative industry. Join XYZMER and witness the
          transformation of the creative industry firsthand!
        </p>
        <div className="wrapbtn 2xl:w-[50%] xl:w-[50%] lg:w-[50%] w-[20%] flex justify-center items-center mt-5">
          <a
          href="/whitepaper"
          target="/blank"
            className="btn 2xl:mt-10 xl:mt-10 lg:mt-10 justify-center flex items-center 2xl:px-[300px] 2xl:py-[25px] xl:px-[150px] lg:px-[150px] px-24 py-3 gap-3  text-white"
            
          >
            <img
              className="w-auto 2xl:h-[40px] xl:h-[40px] lg:h-[30px] h-[20px]"
              src={Paper}
              alt="Whitepaper"
            />
            <span className="2xl:text-[30px] xl:text-[30px] lg:text-[20px] text-[14px]">
              Whitepaper
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Title;
