import React from "react";
import Banner1 from "../../../asset/Bannersm.png";
import NextBtn from "../Component/Button/NextBtn";
import PrevBtn from "../Component/Button/PrevBtn";
import { GoArrowRight } from "react-icons/go";

const Security = ({ setOnActive, onActive }) => {
  return (
    <div className="securitycontent w-full h-full overflow-y-hidden overflow-x-hidden">
      <div className="wraptitles 2xl:w-[90%] w-[90%] mx-auto 2xl:mx-[100px] ">
        <div className="titles w-full flex mt-[30px]">
          <div className="contains gap-3 2xl:mb-12 2xl:mt-4 xl:mb-12 xl:mt-4 lg:mb-12 lg:mt-4 flex flex-col text-left">
            <img src={Banner1} alt="" />
            <p className="text-white  font-bold 2xl:text-[40px] xl:text-[40px] lg:text-[40px] text-[30px]  mt-[30px] ">
              Security and Compliance
            </p>
            <p className="text-white  font-bold 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[16px] mt-[10px] ">
              Security Measures
            </p>

            <p className="text-white w-full 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
              XYZMER COIN is dedicated to providing a secure trading environment
              and safeguarding user funds through rigorous contract audits,
              multisignature wallets, and continuous security monitoring.
            </p>
            <a href="https://www.cognitos.io/xyzmercoin-audit" target="./blank" className=" flex justify-start items-center">
            <p className="flex justify-center items-center gap-1 text-blue-500  2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] mt-5 italic underline">
              Audit Cognitos
              <GoArrowRight  />
            </p>
            </a>

            <a href="https://www.cognitos.io/xyzmercoin-kyc" target="./blank" className=" flex justify-start items-center">
            <p className="flex justify-center items-center gap-1 text-blue-500  2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] mt-1 italic underline">
              KYC Cognitos
              <GoArrowRight  />
            </p>
            </a>
            

            <p className="text-white mt-10 font-bold 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-full ">
              Compliance Requirements
            </p>
            <p className="text-white w-full 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
            XYZMER COIN is committed to adhering to local laws and regulations, maintaining close cooperation with regulatory authorities.
            </p>

            <div className="wrap w-full h-full 2xl:flex xl:flex lg:flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-center items-center 2xl:gap-10 xl:gap-10 lg:gap-10 ">
              <PrevBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="Team and Advisors"
                link="/whitepaper/teams"
              />
              <NextBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="Risk Disclosure"
                link="/whitepaper/risk"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
