

import { Spiral as Hamburger } from "hamburger-react";
import Coins from "../../../../src/asset/XYZMER COIN.png";



const Navbar = ({navOpen, setNavOpen}) => {
    
  return (
    <nav className="Navbar fixed top-0 left-0 w-full h-[60px] bg-white/20 backdrop-blur-lg">
        <div className="warpnav h-[60px] px-5 flex justify-between items-center ">
        <a
        href="/"
         className="left 2xl:w-full h-full flex justify-center items-center gap-2">
      <img className="w-auto 2xl:h-[30px] xl:h-[30px] lg:h-[30px] h-[30px]" src={Coins} alt="" />
      <p className="font-extrabold text-white">XYZMER COIN</p>
      </a>
      <div className="right 2xl:w-full h-full justify-center items-center 2xl:hidden block">
      <Hamburger color="#fff" toggled={navOpen} toggle={setNavOpen} />
      </div>
      </div>
      
    </nav>
  );
};

export default Navbar;
