import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Page/Home";
import P2p from "./Page/P2p";
import Faq from "./Page/Faq";
import Coming from "./PageComponent/coming soon/Playtoearn"
import Nav from "./Component/Nav/Navbar";
import AppWp from "./Component/app-wp";

function App() {
  const location = useLocation();
  const showNav = !location.pathname.startsWith("/whitepaper");

  return (
    <div className="App">
      <div>
        {showNav && <Nav />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/p2p" element={<P2p />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/comingsoon" element={<Coming />} />

          <Route path="/whitepaper/*" element={<AppWp />} /> 
        </Routes>
      </div>
    </div>
  );
}

export default App;
