import { Routes, Route } from "react-router-dom";
import Home from "./whitepaper/Page/Home";
import "../App.css"
import Navbar from "./whitepaper/Component/Navbar";
import { SidebarMobile, SideBarDesktop } from "./whitepaper/Component/Sidebar";
import { useState } from 'react';
import Tokenomic from "./whitepaper/Page/Tokenomic";
import Features from "./whitepaper/Page/Features";
import Listing from "./whitepaper/Page/Listing";
import Community from "./whitepaper/Page/Community";
import Teams from "./whitepaper/Page/Teams";
import Ecosystem from "./whitepaper/Page/Partnership";
import Security from "./whitepaper/Page/Security";
import Risk from "./whitepaper/Page/Risk";
import Conclution from "./whitepaper/Page/Conclution";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation } from "react-router-dom";

function AppWp() {
  const [navOpen, setNavOpen] = useState(false);
  const [onActive, setOnActive] = useState();
  const location = useLocation();

  return (
    <div className="App">
      <div className="layout w-full h-screen bg-[#01062E]">
        <Navbar navOpen={navOpen} setNavOpen={setNavOpen} />
        <div className="contentwrap w-full flex items-start">
          <div className={`sidebars 2xl:w-[300px] xl:w-[300px] lg:w-[300px] w-0 flex bg-[#01062E]`}>
            <SidebarMobile onActive={onActive} setOnActive={setOnActive} navOpen={navOpen} setNavOpen={setNavOpen} />
            <SideBarDesktop onActive={onActive} setOnActive={setOnActive} />
          </div>
          <div className="content flex-1 justify-center">
            <TransitionGroup>
              <CSSTransition key={location.key} classNames="fade" timeout={300}>
                <Routes>
                  <Route path="/" element={<Home onActive={onActive} setOnActive={setOnActive} />} />
                  <Route path="/tokenomic" element={<Tokenomic onActive={onActive} setOnActive={setOnActive} />} />
                  <Route path="/features" element={<Features onActive={onActive} setOnActive={setOnActive} />} />
                  <Route path="/listing" element={<Listing onActive={onActive} setOnActive={setOnActive} />} />
                  <Route path="/community" element={<Community onActive={onActive} setOnActive={setOnActive} />} />
                  <Route path="/teams" element={<Teams onActive={onActive} setOnActive={setOnActive} />} />
                  <Route path="/ecosystem" element={<Ecosystem onActive={onActive} setOnActive={setOnActive} />} />
                  <Route path="/security" element={<Security onActive={onActive} setOnActive={setOnActive} />} />
                  <Route path="/risk" element={<Risk onActive={onActive} setOnActive={setOnActive} />} />
                  <Route path="/conclution" element={<Conclution onActive={onActive} setOnActive={setOnActive} />} />
                </Routes>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppWp;
