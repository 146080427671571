import React from "react";
import Logo from "../../asset/XYZMER LOGOs.png";
// import Coins from "../../asset/Coins.png";
import "./Footer.css";
import {
  FaInstagram,
  FaXTwitter,
  FaMedium,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa6";
import { Link } from "react-router-dom";

const handleScrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  } else {
    console.warn(`Element with id "${id}" not found`);
  }
};

const Footer = () => {
  const handleButtonClick = (linkName) => {
    let externalLink;

    if (linkName === "whitepaper") {
      externalLink = "/whitepaper";
    } else if (linkName === "faq") {
      externalLink = "/faq";
    } else if (linkName === "pck") {
      externalLink =
        "https://t.me/Xyzmercoin_bot/play";
    } else if (linkName === "dext") {
      externalLink =
        "https://www.dextools.io/app/en/bnb/pair-explorer/0xce6160bb594fc055c943f59de92cee30b8c6b32c?t=1716170100955";
    }
    window.open(externalLink, "_blank");
  };

  return (
    <footer className="contfooter w-screen px-5 flex flex-col bg-[#1A245A]  ">
      <div className="footer-content   w-[90%] 2xl:h-[380px] xl:h-[380px] lg:h-[380px] flex 2xl:flex-row xl:flex-row lg:flex-row flex-col 2xl:divide-y-0 xl:divide-y-0 lg:divide-y-0 divide-y-[1px] divide-gray-600 py-2 ">
        <div className="body  2xl:w-1/3 xl:w-1/3 lg:w-1/3 w-full ">
          <div className="wraplogo  w-full flex flex-col justify-start items-start px-10 mt-7  xl:flex gap-5 ">
            <div className="button-wrap flex justify-center items-start text-white font-bold text-[24px] gap-2">
              <Link to="/">
            <button>
              <img
                onClick={() => handleScrollTo("home")}
                className="mt-2 2xl:w-[40px] xl:w-[50px] lg:w-[30px] md:w-[30px] w-[50px] h-auto "
                src={Logo}
                alt=""
              />
              
            </button>
            </Link>
            <p className="2xl:text-[24px] xl:text-[24px] lg:text-[18px] text-[24px]">XYZMER COIN</p>
        
            </div>
            <div className="price w-full flex justify-start items-start">
              <div className="buttonact  4xl:w-[270px] 2xl:w-[270px] xl:w-[270px] lg:w-[270px] w-[270px]  2xl:h-[40px] xl:h-[40px] lg:h-[40px] h-[40px] flex gap-4">
                {/* <button
                  onClick={() => handleButtonClick("dext")}
                  className="text-white font-semibold hover:text-orange-400 flex justify-center items-center gap-2"
                >
                  <img className="w-auto h-[30px]" src={Coins} alt="" />
                  <span>$0.004134</span>
                </button> */}
                <button
                  className="btn "
                  onClick={() => handleButtonClick("pck")}
                >
                  <span className="text-[14px] ">Play to earn</span>
                </button>
              </div>
            </div>
          </div>

          <div className="Headfooter flex-col w-full flex  justify-start items-start gap-5 px-10 mb-5 mt-5 divide-y divide-slate-600 2xl:divide-y-0">
            <p className=" text-[#CCCCCC] mb-5 w-full text-left ">
              XYZMER is more than just a platform, it is a revolution in the
              world of creative art that brings transparency, participation, and
              innovation to every aspect of the industry.
            </p>
          </div>
        </div>
        <div className="body  2xl:w-1/3 xl:w-1/3 lg:w-1/3 w-full py-5 flex justify-start items-start">
          <div className="content1  flex-col flex py-5 gap-8 px-10 2xl:mx-auto xl:mx-auto lg:mx-auto mx-0 justify-start items-start">
            <p className="text-white font-bold text-xl">Home</p>
            <Link to="/">
            <button
              onClick={() => handleScrollTo("home")}
              className="text-[#ccc] text-left "
            >
              About XYZMER Coin
            </button>
            </Link>
            <Link to="/">
            <button
              onClick={() => handleScrollTo("tokenomic")}
              className="text-[#ccc] text-left"
            >
              Tokenomics
            </button>
            </Link>
            <Link to="/">
            <button
              onClick={() => handleScrollTo("roadmap")}
              className="text-[#ccc] text-left"
            >
              Roadmap
            </button>
            </Link>
          </div>
        </div>
        <div className="body 2xl:w-1/4 xl:w-1/4 lg:w-1/4 w-full py-5 flex  items-start ">
          <div className="content1 flex-col flex justify-start items-start py-5 gap-8 px-10 2xl:mx-auto xl:mx-auto lg:mx-auto mx-0">
            <p className="text-white font-bold text-xl">XYZMER</p>
            <button
              onClick={() => handleButtonClick("whitepaper")}
              className="text-[#ccc]"
            >
              Whitepaper
            </button>
            <button
              onClick={() => handleScrollTo("community")}
              className="text-[#ccc] text-left"
            >
              Community
            </button>
            <button
              onClick={() => handleScrollTo("partnership")}
              className="text-[#ccc]"
            >
              Our Partners
            </button>
          </div>
        </div>
        <div className="body 2xl:w-1/4 xl:w-1/4 lg:w-1/4 w-full py-5 flex  items-start">
          <div className="content1 flex-col flex py-5 gap-8 px-10 2xl:mx-auto xl:mx-auto lg:mx-auto mx-0 justify-start items-start">
            <p className="text-white font-bold text-xl">Support</p>
            <a
              href="https://t.me/xyzmercoin_Channel"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#ccc]"
            >
              Help Center
            </a>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScGmbqZg51HkS_SBrz2YS82HvWs9JdvAcJo8nl5cGhAv2AftA/viewform"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#ccc]"
            >
              Contact Us
            </a>
            <a
            href="/faq"
              
              className="text-[#ccc] text-left"
            >
              FaQ
            </a>
          </div>
        </div>
      </div>
      <span className="h-[1px] w-full bg-gray-600"></span>
      <div className="footer-bottom flex justify-between items-center py-6 2xl:flex-row xl:flex-row lg:flex-row flex-col gap-5  ">
        <div className="social flex items-center  gap-5 px-10">
          <p className="text-white font-md text-sm 2xl:block xl:block lg:block hidden ">
            Find Us Here:
          </p>
          <div className="wrapimg flex justify-start gap-6 text-white text-2xl">
            <a
              href="https://www.instagram.com/xyzmercoinofficial?igsh=aGxnOHZ0aTI0eTY2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="w-[20px] h-auto hover:shadow-xl hover:scale-150 transition-all duration-300 text-white" />
            </a>
            <a
              href="https://x.com/XyzmerCoin?t=AoFukEkDMlXbMHawMpK5DA&s=09"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaXTwitter className="w-[20px] hover:shadow-xl hover:scale-150 transition-all duration-300 h-auto text-white" />
            </a>
            {/* <a
              href="https://medium.com"
              target="_blank"
              rel="noopener noreferrer"
            > */}
              <FaMedium className="w-[20px] hover:shadow-xl hover:scale-150 transition-all duration-300 h-auto text-white" />
            {/* </a> */}
            <a
              href="https://www.tiktok.com/@xyzmercoin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTiktok className="w-[20px] hover:shadow-xl hover:scale-150 transition-all duration-300 h-auto text-white" />
            </a>
            <a
              href="https://www.youtube.com/@XyzmerCoin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube className="w-[20px] hover:shadow-xl hover:scale-150 transition-all duration-300 h-auto text-white" />
            </a>
          </div>
        </div>
        <div className="copy flex items-center gap-5 px-10">
          <p className=" font-md text-base italic text-gray-400 ">
            © 2024 XYZgroup. All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
