import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GoArrowRight } from "react-icons/go";

const NextBtn = ({label, link, onActive, setOnActive }) => {
const navigate = useNavigate();
const handleNext = (path) => {
  
  navigate(path); 
  window.scrollTo(0, 0);
  setOnActive(path)
}



  return (
    <button onClick= {() => handleNext(link)} className="right 2xl:w-1/2 xl:w-1/2 w-full gap-3  rounded-2xl px-5 2xl:py-5 xl:py-5 lg:py-5 py-5 border-[1px] border-white 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[40px] mb-[20px] flex justify-between items-center">
          <div className="flex justify-start flex-col gap-3 px-3">
            <p className="text-white text-start font-light 2xl:text-sm xl:text-sm lg:text-sm text-xs">Next</p>
            <p className="text-white font-bold 2xl:text-lg xl:text-lg lg:text-lg text-sm ">{label}</p>
            
            

          </div>
          <div className="text-white text-lg px-3 "><GoArrowRight /></div>
          
          </button>
  )
}

export default NextBtn