import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Milestone.css";

const Milestone = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);
  return (
    <section
      data-aos="fade-up"
      data-aos-delay="100"
      className="milestone w-[90%] 2xl:w-[90%] justify-center flex 2xl:mt-[150px] xl:-mt[70px] lg:-[70px] mt-[60px]"
    >
    <div className="w-full flex justify-around items-start">
    
    <div className="w-full  flex  justify-center items-start ">
    <div className="w-[25%] flex flex-col rounded-full justify-center items-center">
        <div className=" 2xl:w-[20px] 2xl:h-[20px] w-[15px] h-[15px] bg-blue-400 rounded-full mile border-blue-400 "></div>
        <p className="text-blue-400 mt-5 font-bold 2xl:text-[16px] text-[12px]">Play To Earn</p>

    </div>
    <div className='text-white  w-full 2xl:h-[10px] h-[5px] bg-blue-400 rounded-full mt-1 '>
    
    </div>
    <div className="w-[25%] flex flex-col rounded-full justify-center items-center">
        <div className=" 2xl:w-[20px] 2xl:h-[20px] w-[15px] h-[15px] bg-slate-400 rounded-full  "></div>
        <p className="text-slate-400 mt-5 font-bold 2xl:text-[16px] text-[12px]">5000 User</p>

    </div>
    <div className='text-white  w-full 2xl:h-[10px] h-[5px] bg-blue-400 rounded-full mt-1 '>
    
    </div>
    <div className="w-[25%] flex flex-col rounded-full justify-center items-center">
        <div className=" 2xl:w-[20px] 2xl:h-[20px] w-[15px] h-[15px] bg-slate-400 rounded-full  "></div>
        <p className="text-slate-400 mt-5 font-bold 2xl:text-[16px] text-[12px]">10000 User</p>

    </div>
    <div className='text-white  w-full 2xl:h-[10px] h-[5px] bg-blue-400 rounded-full mt-1 '>
    
    </div>
    <div className="w-[25%] flex flex-col rounded-full justify-center items-center">
        <div className=" 2xl:w-[20px] 2xl:h-[20px] w-[15px] h-[15px] bg-slate-400 rounded-full  "></div>
        <p className="text-slate-400 mt-5 font-bold 2xl:text-[16px] text-[12px]">Presale</p>

    </div>
   
    </div>
    </div>
    </section>
  )
}

export default Milestone