import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Certix from "../../asset/Certix logo.png";
import Cognitos from "../../asset/Cognitos.png"
import KYC from "../../asset/KYC-Certificate-XYZMERCOIN.jpg"
import Audit from "../../asset/Audit NFT Certificate - Cognitos.gif"

const Auditby = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
    });
  }, []);

  return (
    <section data-aos="fade-up" data-aos-delay="300" className="contauditby justify-center items-center flex flex-col gap-5 mt-[100px] 2xl:mt-20  ">
        <span className="text-white font-bold 2xl:text-[56px] xl:text-[50px] lg:text-[50px] text-[20px] ">Audit Plan</span>
        <div className="logo-wrap 2xl:flex xl:flex-col lg:flex-col md:flex-col flex-col justify-center items-center gap-10">
          <div className="w-full flex justify-center items-center mt-5">
        <img className="2xl:w-[400px] w-[200px] h-[auto] " src={Certix} alt="" />
        </div>
        <div className="cognitos flex flex-col w-full justify-center items-center mt-5">
        <img className="2xl:w-[400px] w-[200px] h-[auto] mt-10" src={Cognitos} alt="cognitos" />
        <div className="flex-col flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row w-full justify-center items-center gap-20 2xl:mt-20 mt-12">
          <a href='https://www.cognitos.io/xyzmercoin-audit' target='/blank' className="flex flex-col 2xl:w-[300px] w-[150px] h-auto gap-5">
          <img src={Audit} alt="audit cognitos" />
          <p className='text-white font-semibold'>Audit Cognitos</p>
          </a>
          <a href='https://www.cognitos.io/xyzmercoin-kyc ' target='/blank' className="flex flex-col justify-center items-center 2xl:w-[425px] w-[175px] h-auto gap-5 2xl:mt-0 xl:mt-0 lg:mt-0 mt-0">
          <img src={KYC} alt="kyc cognitos" />
          <p className='text-white font-semibold'>KYC Cognitos</p>
          </a>
        </div>
        </div>
        </div>
        
      </section>
  )
}

export default Auditby