// import React, {useEffect, useState} from "react";
import { sideButton } from "./Constant/ConsSidebar";
// import { Notes } from "./iconSVG/IconSVG";
import { useNavigate } from "react-router-dom";
import SidebarBtn from "./Button/SidebarBtn";
import Coins from "../../../../src/asset/XYZMER COIN.png";



export const SidebarMobile = ({navOpen, setNavOpen, setOnActive, onActive}) => {
 console.log(navOpen)

  const navigate = useNavigate();
  const handleHome = () => {
    navigate("/whitepaper");
    setOnActive(false);
    window.scrollTo(0, 0);
  }
  

  const handleNav = (path) => {
    navigate(path);
    setOnActive(path);
    setNavOpen(false);
    window.scrollTo(0, 0);
  }

  return (
  
    <div className={`sidebar fixed block transition-all duration-300 bg-[#282c34] w-[300px] ${navOpen ? "translate-x-0 " : "-translate-x-[300px] " } 2xl:hidden xl:hidden lg:hidden `}>
      <div className={`wrapside absolute   w-full flex-col items-start pt-5 gap-5 pl-2 `}>
        <button onClick={handleHome} className="conten flex  w-full h-[100px] items-center justify-start gap-2 mb-2 px-3">
          <img className="h-auto w-[30px]" src={Coins} alt="" />
          <p className="text-white flex justify-start items-start font-bold text-[14px]">
            XYZMER Coin Whitepaper 
          </p>
        </button>
        
        {/* <button className="hover:bg-[#2761B8] hover:rounded-lg w-full h-[60px] group  flex items-center gap-5 ">
          <img className="w-auto h-[30px]" src={Coin} alt="" />
          <span className="text-white group-hover:text-orange-400 ">
            {" "}
            Features and Innovation
          </span>
        </button> */}
        {sideButton.map((item, index) => 
            
                // <button
                // onClick={() => handleNav(item.link)}
                // key={index}
                // className={`hover:bg-[#3d4db7] hover:rounded-lg w-full h-[80px] group flex items-center pl-5 ${onActive === item.link ? "bg-[#3d4db7] rounded-lg" : ""}`}
                // >
                // {/* <img className="w-auto h-[30px]" src={item.icon} alt="" /> */}
                // {/* {item.icon} */}
                // <span className="text-white group-hover:text-orange-400 ">
                //     {" "}
                //     {item.title}
                // </span>
                // </button>
                <SidebarBtn key={index} link={item.link} label={item.title} onActive={onActive} onClick={() => handleNav(item.link)} />
    
        )}

        
      </div>
    </div>
    
  );
};

export const SideBarDesktop = ({navOpen, setNavOpen, onActive, setOnActive}) => {
  const navigate = useNavigate();
  const handleHome = () => {
    navigate("/whitepaper");
    setOnActive(false);
    window.scrollTo(0, 0);
  }
  

  const handleNav = (path) => {
    navigate(path);
   setOnActive(path);
   window.scrollTo(0, 0);
  }



 console.log(onActive, onActive === "/features")
 
 
   return (
   
     <div className={`sidebar  fixed hidden transition-all duration-300 bg-[#282c34] w-[350px] 2xl:block xl:block lg:block `}>
       <div className={`wrapside w-full flex-col justify-center items-start py-4  pl-6  `}>
         <button onClick={handleHome} className="title flex w-full gap-3 items-center mb-8   ">
           <img className="h-[55px] w-auto " src={Coins} alt="" />
           <div className="title flex flex-col justify-center items-start">
           <p className="text-white font-bold text-[20px] ">
             XYZMER COIN 
           </p>
           <p className="text-white font-bold text-[20px] ">
              Whitepaper 
           </p>
           </div>
         </button>
         
         {/* <button className="hover:bg-[#2761B8] hover:rounded-lg w-full h-[60px] group  flex items-center gap-5 ">
           <img className="w-auto h-[30px]" src={Coin} alt="" />
           <span className="text-white group-hover:text-orange-400 ">
             {" "}
             Features and Innovation
           </span>
         </button> */}
         {sideButton.map((item, index) => 
             
                //  <button
                //  key={index}
                //  onClick={() => handleNav(item.link)} 
                //  className={`hover:bg-[#3d4db7] hover:rounded-lg w-full h-[80px] group flex items-center pl-5 ${onActive === item.link ? "bg-[#3d4db7] rounded-lg" : ""}`}
                //  >
                //  {/* <img className="w-auto h-[30px]" src={item.icon} alt="" /> */}
                //  {/* {item.icon} */}
                //  <span className="text-white group-hover:text-orange-400 ">
                //      {" "}
                //      {item.title}
                //  </span>
                //  </button>
                
                <SidebarBtn key={index} link={item.link} label={item.title} onActive={onActive} onClick={() => handleNav(item.link)} />
                
         )}
 
         
       </div>
     </div>
     
   );
 };


