import React from "react";
import Banner1 from "../../../asset/Bannersm.png";
import NextBtn from "../Component/Button/NextBtn";
import PrevBtn from "../Component/Button/PrevBtn";

const Ecosystem = ({ setOnActive, onActive }) => {
  return (
    <div className="Partnershipcontent w-full h-full overflow-y-hidden overflow-x-hidden">
      <div className="wraptitles 2xl:w-[90%] w-[90%] mx-auto 2xl:mx-[100px]">
        <div className="titles w-full flex mt-[30px]">
          <div className="contains gap-3 2xl:mb-12 2xl:mt-4 xl:mb-12 xl:mt-4 lg:mb-12 lg:mt-4 flex flex-col text-left">
            <img src={Banner1} alt="" />
            <p className="text-white font-bold 2xl:text-[40px] xl:text-[40px] lg:text-[40px] text-[30px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[30px]">
              XYZMER COIN Ecosystem
            </p>
            <ul className="list-decimal list-inside text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] ml-5">
              XYZMER COIN introduces two main platforms in its ecosystem:
              <li className="px-3 font-bold mt-3">
                <a
                  href="https://xyznt.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                  className="font-bold underline italic"
                >
                  xyznt.io:
                </a>{" "}
                This NFT marketplace platform enables creators to sell their
                digital artworks. 5% of the net revenue from xyznt.io will be
                allocated to XYZMER COIN liquidity.
              </li>
              <li className="px-3 font-bold mt-3">
                <button className="font-bold underline italic">
                  Crypto Media Web3:
                </button>{" "}
                XYZMER COIN adopts an innovative business model as a web3-based
                crypto media platform, where users, contributors, and editorial
                teams can directly benefit from the news they read and create
                through token-based rewards with the concepts of "write to earn"
                and "read to earn." In addition to providing incentives to
                active users, XYZMER COIN is also exploring a blockchain-based
                subscription model that offers premium access for users or
                readers who wish to obtain exclusive information, thereby
                providing a more valuable experience for the user community. 5%
                of the net revenue from Crypto media web3 will also be allocated to
                XYZMER COIN liquidity.
              </li>
            </ul>
            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] w-full 2xl:mt-[40px] xl:mt-[40px] lg:mt-[40px] mt-[40px] mb-[20px]">
              The revenue sharing from this ecosystem is expected to enhance
              resilience and reduce price fluctuations of XYZMER COIN.
            </p>
            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] w-full mb-[50px]">
              XYZMER COIN is not just a platform for the creative industry; it
              is a revolution in the art world that brings transparency,
              participation, and innovation to every aspect of the industry. By
              joining XYZMER COIN, investors are not only investing in a
              business but also in the future of a fair and sustainable art
              industry.
            </p>

            <div className="wrap w-full h-full 2xl:flex xl:flex lg:flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-center items-center 2xl:gap-10 xl:gap-10 lg:gap-10">
              <PrevBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="Features And Innovation"
                link="/whitepaper/features"
              />
              <NextBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="Listing Plan XYZMER COIN"
                link="/whitepaper/listing"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecosystem;
