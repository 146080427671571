import React from 'react'
import Coming from "../../asset/Coming-Soon.jpg"
import ComingMobile from "../../asset/Coming-soon-mobile.jpg"

const Playtoearn = () => {
  return (
    <div className=' w-screen h-screen '>
        <img src={Coming} alt="" className='w-[1920px] h-auto 2xl:block xl:blockc lg:block md:block hidden' />
        <img src={ComingMobile} alt="" className='w-[600px] h-auto  2xl:hidden xl:hidden lg:hidden md:hidden block justify-center items-center' />
    </div>
  )
}

export default Playtoearn