import React from "react";
import Banner1 from "../../../asset/Bannersm.png";
import NextBtn from "../Component/Button/NextBtn";

const Home = ({ setOnActive, onActive }) => {
  return (
    <div className="homecontent w-full h-full overflow-y-hidden overflow-hidden">
      <div className="wraptitles 2xl:w-[90%] w-[90%] mx-auto 2xl:mx-[100px] ">
        <div className="titles w-full flex mt-[30px]">
          <div className="contains gap-3 2xl:mb-12 2xl:mt-4 xl:mb-12 xl:mt-4 lg:mb-12 lg:mt-4 flex flex-col text-left">
            <img className="banner1" src={Banner1} alt="" />
            <div className="contains 2xl:mt-16 xl:mt-16 lg:mt-16 mt-5 flex flex-col text-left   ">
              <p className="text-white font-semibold 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[30px] xl:text-[30px] lg:text-[30px] text-[12px] ">
                Welcome to the XYZMER COIN Whitepaper, your guide to
                understanding our revolutionary project. In this document, we
                will explain the vision, goals, and technical features of XYZMER
                COIN.
              </p>
              <p className="text-white font-bold 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[30px] xl:text-[30px] lg:text-[30px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[50px] mb-5">
                Project Overview
              </p>
              <p className="text-white 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px]  xl:text-[20px] lg:text-[20px] text-[12px]">
                The creative industry plays a crucial role in economic growth.
                However, it faces challenges such as ineffective copyright
                management, increased piracy, and non-transparent royalty
                distribution. Issues like unclear copyrights and inefficient
                royalty distribution drive the need for innovative solutions.
                XYZMER COIN aims to become a protocol token that enhances
                transparency and efficiency in royalty distribution within the
                creative industry through secure blockchain technology.
              </p>

              <p className="text-white 2xl:w-[70%] xl:w-[70%] lg:w-[70%] font-bold 2xl:text-[30px] xl:text-[30px] lg:text-[30px] text-[20px] mt-[70px] mb-5">
                Blockchain Technology for Transparent Royalty Distribution
              </p>
              <p className="text-white 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
                XYZMER COIN leverages secure and decentralized blockchain
                technology to address uncertainties in the creative industry.
                This technology ensures clear and immutable records of every
                royalty transaction, guaranteeing fair compensation for creators
                and industry participants.
              </p>

              <p className="text-white 2xl:w-[70%] xl:w-[70%] lg:w-[70%] font-bold 2xl:text-[30px] xl:text-[30px] lg:text-[30px] text-[20px] mt-[70px] mb-5">
                Coin Information: XYZMER (BEP20) on Binance Smart Chain
              </p>
              <p className="text-white 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
                XYZMER, based on Binance Smart Chain, has a total supply of
                666,666,666. Its tokenomics includes allocations for CEX
                Listing, Marketing, R&D, and Circulating Supply, with specific
                percentages.
              </p>
              <p className="text-white 2xl:w-[70%] xl:w-[70%] lg:w-[70%] font-bold 2xl:text-[30px] xl:text-[30px] lg:text-[30px] text-[20px] mt-[70px] mb-5">
                Tax System
              </p>
              <p className="text-white 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
                The tax system includes a 2% purchase fee and a 5% sales fee.
                Once the market capitalization reaches $100 million, we will
                completely eliminate the buy and sell tax. Additionally,
                developers will not utilize the allocation for research &
                development and community building while the tax is
                still in effect.
              </p>

              <p className="text-white 2xl:w-[70%] xl:w-[70%] lg:w-[70%] font-bold 2xl:text-[30px] xl:text-[30px] lg:text-[30px] text-[20px] mt-[70px] mb-5">
                Creating an Ecosystem that Values Creativity
              </p>
              <p className="text-white 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
                XYZMER COIN collectively addresses the challenges of the
                creative industry by offering innovative, transparent, and
                sustainable solutions. The combination of blockchain technology
                is expected to pioneer the transformation of the creative
                industry into a fair, dynamic, and competitive sector.
              </p>

              {/* <Link to="/tokenomic" className="borderwrap 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-full gap-3 h-full rounded-2xl px-5 2xl:py-5 xl:py-5 lg:py-5 py-5 border-[1px] border-white 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[70px] mb-[50px] flex justify-between ">
          <div className="flex justify-start flex-col gap-3 px-3">
            <p className="text-white font-light text-sm">Next</p>
            <p className="text-white font-bold text-lg">Tokenomic</p>
            
            

          </div>
          <button className="text-white text-lg px-3 "><GoArrowRight /></button>
          
          </Link> */}
              <div className="wrap w-full h-full 2xl:flex xl:flex lg:flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-start mb-10 items-start 2xl:gap-10 xl:gap-10 lg:gap-10 ">
                <NextBtn
                  onActive={onActive}
                  setOnActive={setOnActive}
                  label="Token Economic"
                  link="/whitepaper/tokenomic"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
