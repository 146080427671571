import React from 'react'

const SidebarBtn = ({link, label, onActive, onClick}) => {
  return (
    <button
    onClick={onClick}
 
    className={` w-full h-[80px] group flex items-center pl-5  ${onActive === link ? "bg-[#3d4db7] rounded-lg " : ""}`}
    >
    {/* <img className="w-auto h-[30px]" src={item.icon} alt="" /> */}
    {/* {item.icon} */}
    <span className="text-white group-hover:text-orange-400 ">
        {" "}
        {label}
    </span>
    </button>
  )
}

export default SidebarBtn