import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Faq = () => {
  const [expandedSections, setExpandedSections] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
    option5: false,
    option6: false,
    option7: false,
    option8: false,
    option9: false,
    option10: false,
    option11: false,
    option12: false,
    option13: false,
    option14: false,
    option15: false,
    option16: false,
    option17: false,
    option18: false,
    option19: false,
    option20: false,
    option21: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="Activity-sec w-full 2xl:flex-col flex flex-row justify-center items-center mb-20 ">
      <div className="wrap-activity w-full">
        <div className="wrap-title mt-10 px-10 w-full">
          <h1 className="text-white font-bold text-[34px] text-left">FAQ</h1>
        </div>
        <div className="line w-full h-[1px] bg-white/20 mt-10"></div>
        <div className="wrap-op1 mt-20">
          <div className="wrap-title my-10 px-10 w-full">
            <h1 className="text-white font-bold text-[24px] text-left">
              XYZMER Coin FAQ
            </h1>
          </div>

          <div className="option-1 w-full mt-10 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option1")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                What is XYZMER COIN?
              </div>
              {expandedSections.option1 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option1
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option1 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 my-5">
                  <p className="text-white font-bold text-[18px] pl-3">
                    XYZMER COIN is a protocol token designed to enhance
                    transparency and efficiency in royalty distribution within
                    the creative industry through secure blockchain technology.
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="option-2 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option2")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                Why is XYZMER COIN needed?
              </div>
              {expandedSections.option2 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option2
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option2 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 my-5">
                  <p className="text-white font-bold text-[18px] pl-3">
                    The creative industry faces challenges like ineffective
                    copyright management, increased piracy, and non-transparent
                    royalty distribution. XYZMER COIN aims to address these
                    issues with innovative solutions.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="wrap-op2 my-20">
          <div className="wrap-title mt-14 px-10 w-full">
            <h1 className="text-white font-bold text-[24px] text-left">
              Technology
            </h1>
          </div>

          <div className="option-3 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option3")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                How does XYZMER COIN utilize blockchain technology?
              </div>
              {expandedSections.option3 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option3
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option3 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    XYZMER COIN leverages secure and decentralized blockchain
                    technology to ensure clear and immutable records of every
                    royalty transaction, guaranteeing fair compensation for
                    creators and industry participants.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="option-4 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option4")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                What blockchain is XYZMER COIN based on?
              </div>
              {expandedSections.option4 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option4
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option4 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    XYZMER COIN is based on the Binance Smart Chain (BSC),
                    chosen for its efficient transaction speed and low costs.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="wrap-op3 my-20">
          <div className="wrap-title mt-14 px-10 w-full">
            <h1 className="text-white font-bold text-[24px] text-left">
              Token Details
            </h1>
          </div>

          <div className="option-5 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option5")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                What is the total supply of XYZMER COIN?
              </div>
              {expandedSections.option5 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option5
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option5 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    The total supply of XYZMER COIN is 666,666,666 tokens.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="option-6 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option6")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                What are the key allocations for XYZMER COIN?
              </div>
              {expandedSections.option6 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option6
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option6 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4 ">
                  <ul className="text-white font-bold text-[18px] marker:text-sky-400 list-disc pl-10 space-y-2 text-left">
                    <li>Total Supply: 666,666,666</li>
                    <li>Token Generation Event (TGE): 33.5%</li>
                    <li>Play to earn: 33.5%</li>
                    <li>Exchanger: 26.0%</li>
                    <li>Community Building: 5.0%</li>
                    <li>Research and Development : 2.0%</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="option-7 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option7")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                What are the transaction fees for XYZMER COIN?
              </div>
              {expandedSections.option7 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option7
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option7 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3 text-left">
                    There is a 2% purchase fee and a 5% sales fee. (Once the
                    market capitalization reaches $100 million, we will
                    completely eliminate the buy and sell tax. Additionally,
                    developers will not utilize the allocation for research &
                    development and community building while the tax is still in
                    effect.)
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="wrap-op4 my-20">
          <div className="wrap-title mt-14 px-10 w-full">
            <h1 className="text-white font-bold text-[24px] text-left">
              Ecosystem
            </h1>
          </div>

          <div className="option-8 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option8")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                What platforms are included in the XYZMER COIN ecosystem?
              </div>
              {expandedSections.option8 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option8
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option8 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4 ">
                  <ul className="text-white font-bold text-[18px] marker:text-sky-400 list-decimal     pl-10 space-y-2 text-left">
                    <li>
                      <span className="text-sky-400">xyznt.io:</span> An NFT
                      marketplace for creators to sell their digital artworks.
                    </li>
                    <li>
                      <span className="text-sky-400">Crypto Media Web3: </span>{" "}
                      XYZMER COIN adopts an innovative business model as
                      a web3-based crypto media platform, where users,
                      contributors, and editorial teams can directly benefit
                      from the news they read and create through token-based
                      rewards with the concepts of "write to earn" and "read to
                      earn." In addition to providing incentives to active
                      users, XYZMER COIN is also exploring a blockchain-based
                      subscription model that offers premium access for users or
                      readers who wish to obtain exclusive information, thereby
                      providing a more valuable experience for the user
                      community. 5% of the net revenue from Crypto media web3
                      will also be allocated to XYZMER COIN liquidity.
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="option-9 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option9")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                How does the revenue sharing work in the ecosystem?
              </div>
              {expandedSections.option9 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option9
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option9 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    5% of the net revenue from both xyznt.io and Crypto media web3 will
                    be allocated to XYZMER COIN liquidity.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="wrap-op5 my-20">
          <div className="wrap-title mt-14 px-10 w-full">
            <h1 className="text-white font-bold text-[24px] text-left">
              Features and Innovations
            </h1>
          </div>
          <div className="option-10 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option10")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                How does XYZMER COIN support the creative industry?
              </div>
              {expandedSections.option10 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option10
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option10 && (
                <div className="wrap-content flex flex-col text-left justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3 ">
                    XYZMER COIN offers innovative solutions such as tokenizing
                    creative works for funding, enhancing digital rights
                    management with smart contracts, and creating a crypto-based
                    marketplace for direct interaction between creators and
                    fans.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="option-11 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option11")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                What is the role of NFTs in XYZMER COIN?
              </div>
              {expandedSections.option11 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option11
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option11 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    XYZMER COIN integrates NFT technology to enable the creation
                    and trading of unique digital collections, adding value for
                    both creators and fans.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="option-12 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option12")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                How does XYZMER COIN incentivize active users?
              </div>
              {expandedSections.option12 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option12
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option12 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    XYZMER COIN provides token-based incentives and explores
                    blockchain-based subscription models to offer a more
                    valuable experience for the user community.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="wrap-op6 my-20">
          <div className="wrap-title mt-14 px-10 w-full">
            <h1 className="text-white font-bold text-[24px] text-left">
              Partnerships and Community
            </h1>
          </div>
          <div className="option-13 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option13")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                How does XYZMER COIN approach partnerships?
              </div>
              {expandedSections.option13 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option13
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option13 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    XYZMER COIN seeks to collaborate with entities in the
                    blockchain ecosystem and the creative industry, including
                    technology partners, exchange partners, and media partners.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="option-14 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option14")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                What is the community focus of XYZMER COIN?
              </div>
              {expandedSections.option14 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option14
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option14 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    XYZMER COIN aims to bridge the art community, the creative
                    industry, and the crypto space, leveraging NFTs, providing
                    education, hosting special events, and implementing tokens
                    as membership indicators.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="wrap-op7 my-20">
          <div className="wrap-title mt-14 px-10 w-full">
            <h1 className="text-white font-bold text-[24px] text-left">
              Security and Compliance
            </h1>
          </div>
          <div className="option-15 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option15")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                How does XYZMER COIN ensure security?
              </div>
              {expandedSections.option15 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option15
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option15 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    XYZMER COIN is committed to security through rigorous
                    contract audits, multisignature wallets, and continuous
                    security monitoring.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="option-16 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option16")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                How does XYZMER COIN ensure compliance?
              </div>
              {expandedSections.option16 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option16
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option16 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    XYZMER COIN complies with local laws and regulations,
                    maintaining close cooperation with regulatory authorities
                    and obtaining the necessary licenses to ensure platform
                    compliance.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="wrap-op8 my-20">
          <div className="wrap-title mt-14 px-10 w-full">
            <h1 className="text-white font-bold text-[24px] text-left">
              Investment Risks
            </h1>
          </div>
          <div className="option-17 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option17")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                What risks should investors consider?
              </div>
              {expandedSections.option17 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option17
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option17 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    Investors should consider market risks, technological risks,
                    legal and compliance risks, execution risks, and
                    crowdfunding risks. It is important to conduct independent
                    risk assessments before investing.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="wrap-op9 my-20">
          <div className="wrap-title mt-14 px-10 w-full">
            <h1 className="text-white font-bold text-[24px] text-left">
              Conclusion
            </h1>
          </div>
          <div className="option-18 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option18")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                What is the vision of XYZMER COIN?
              </div>
              {expandedSections.option18 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option18
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option18 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    XYZMER COIN aims to lead the future of a fair, dynamic, and
                    competitive creative industry by offering innovative,
                    transparent, and sustainable solutions.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="option-19 mt-5 flex flex-col justify-start items-start px-10">
            <button
              className="w-full text-left bg-[#1A245A] text-white rounded-lg p-4 mb-4 flex justify-between items-center hover:bg-[#163d6e] focus:outline-none"
              onClick={() => toggleSection("option19")}
            >
              <div className="font-bold text-[20px] text-blue-200">
                How can one participate in XYZMER COIN?
              </div>
              {expandedSections.option19 ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-white" />
              )}
            </button>
            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                expandedSections.option19
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {expandedSections.option19 && (
                <div className="wrap-content flex flex-col justify-start items-start gap-2 mt-4">
                  <p className="text-white font-bold text-[18px] pl-3">
                    Join XYZMER COIN to be part of the transformation of the
                    creative industry and invest in a fair and sustainable
                    future for the arts.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faq;
