import React from "react";
import Banner1 from "../../../asset/Bannersm.png";
import NextBtn from "../Component/Button/NextBtn";
import PrevBtn from "../Component/Button/PrevBtn";

const Features = ({ setOnActive, onActive }) => {
  return (
    <div className="Featurescontent w-full h-full overflow-y-hidden overflow-x-hidden">
      <div className="wraptitles 2xl:w-[90%] w-[90%] mx-auto 2xl:mx-[100px] ">
        <div className="titles w-full flex mt-[30px]">
          <div className="contains gap-3 2xl:mb-12 2xl:mt-4 xl:mb-12 xl:mt-4 lg:mb-12 lg:mt-4 flex flex-col text-left">
            <img src={Banner1} alt="" />
            <p className="text-white  font-bold 2xl:text-[40px] xl:text-[40px] lg:text-[40px] text-[30px]  mt-[60px] ">
              Features and Innovation
            </p>

            <p className="text-white  font-bold 2xl:text-[26px] xl:text-[26px] lg:text-[26px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px] ">
              Revolutionary Transformation in the Creative Industry
            </p>
            <p className="text-white w-full 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
              In a rapidly evolving digital era, XYZMER COIN emerges as an
              innovation that combines advanced blockchain technology with the
              beauty of creative arts. With a vision to redefine the creative
              industry, XYZMER COIN aims to open doors for active fan
              participation, empower creators and contributors, and create a
              transparent, fair, and sustainable ecosystem.
            </p>

            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] 2xl:mt-[100px]xl:mt-[100px] lg:mt-[100px] mt-[60px]">
              Tokenization of Creative Works and Funding
            </p>
            <p className="text-white w-full 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
              XYZMER COIN introduces a revolutionary funding model through a
              digital asset tokenization platform. Creators can issue NFTs as a
              form of direct funding from their fans. This provides fans with a
              unique opportunity to invest in the creative works they love while
              giving creators the freedom to explore their creativity without
              traditional limitations. Additionally, the integration of NFT
              technology enables the creation and trade of unique digital
              collectibles, opening new opportunities for both creators and
              fans.
            </p>

            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px] ">
              Digital Rights Management
            </p>

            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-full ">
              By leveraging smart contracts on the blockchain, XYZMER COIN makes
              digital rights management more transparent and efficient.
              Copyright security is enhanced, and an automated royalty payment
              system ensures that every use of creative works is fairly
              compensated. Creators, copyright holders, and contributors can
              monitor and receive their royalties in real-time. Moreover, the
              integration of NFT technology allows for more flexible and secure
              management and trading of digital rights, adding value for both
              creators and fans.
            </p>

            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px] ">
              Crypto-Based Marketplace
            </p>
            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-full  ">
              XYZMER COIN creates a crypto-based marketplace where creators and
              copyright holders can sell their works, merchandise, and services
              using crypto assets. Users can interact directly with their
              favorite creators, fostering a more intimate and direct creative
              industry experience. The integration with NFT technology also
              expands opportunities for trading unique and exclusive digital
              works.
            </p>

            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px] ">
              NFT Ecosystem and Digital Collections
            </p>
            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-full  ">
              The integration of NFT technology opens new opportunities for the
              creation and trade of unique digital collectibles. Creators can
              release limited edition merchandise related to their brand,
              providing collective value for the fans who support them.
            </p>

            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px] ">
              Business Model Innovation
            </p>
            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-full  ">
              XYZMER COIN adopts a business model innovation as a WEB3-based
              crypto media platform, where users, contributors, and editorial
              teams can directly benefit from the news they read and create
              through token-based rewards with concepts like "write to earn" and
              "read to earn." In addition to providing incentives for active
              users, XYZMER COIN is also exploring a blockchain-based
              subscription model that offers premium access for users or readers
              who want to obtain exclusive information, thereby offering a more
              valuable experience for the user community.
            </p>

            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px] ">
              Partnerships and Collaboration
            </p>
            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-full  ">
              XYZMER COIN understands the importance of partnerships in
              achieving success. Therefore, XYZMER COIN is open to collaborating
              with other entities within the blockchain ecosystem and the
              creative industry, including crypto asset payment service
              providers and other blockchain technology companies.
            </p>

            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px] ">
              Focus on Technological Innovation
            </p>
            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-full  ">
              Sustained development of new technology is at the core of XYZMER
              COIN's strategy. From intelligent algorithms for better art
              recommendations to AI integration, XYZMER COIN is committed to
              providing innovative creative experiences that set it apart from
              its competitors.
            </p>

            <div className="wrap w-full h-full 2xl:flex xl:flex lg:flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-center items-center 2xl:gap-10 xl:gap-10 lg:gap-10 ">
              <PrevBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="Token Economics"
                link="/whitepaper/tokenomic"
              />
              <NextBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="XYZMER Coin Ecosystem"
                link="/whitepaper/ecosystem"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
