import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Chart from "../../asset/Pie chart_1.png";
import Coins from "../../asset/XYZMER COIN.png";

// import Logotkn from "../../asset/Logo tkn.png";
// import { MdOutlineContentCopy } from "react-icons/md";
import "./Tokenomic.css";

// const copyToClipboard = () => {
//   navigator.clipboard
//     .writeText("0x2bF4BE7C4520C41d003b898534")
//     .then(() => {
//       alert("Text copied to clipboard");
//     })
//     .catch((err) => {
//       console.error("Failed to copy text: ", err);
//     });
// };

const Tokenomic = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
    });
  }, []);
  return (
    <section
      id="tokenomic"
      data-aos="fade-up"
      data-aos-delay="300"
      className="conttokenomic w-full p-5 justify-center items-center flex 2xl:mt-[200px] xl:-mt[180px] lg:mt-[180px] mt-[50px]"
    >
      <div className="wrappertokenomic w-full justify-center flex flex-col items-center gap-6">
        <p className="text-white font-bold flex flex-wrap text-center 2xl:text-[56px] xl:text-[50px] lg:text-[50px] text-[20px] ">
          Tokenomics
        </p>
        <p className="text-white font-bold flex flex-wrap text-center 2xl:text-[56px] xl:text-[50px] lg:text-[50px] text-[20px] ">
          Total supply: 666,666,666
        </p>
        <div className="tokenomic 2xl:w-[80%] xl:w-[80%] lg:w-[80%] w-full mt-20 2xl:gap-14 xl:gap-14 lg:gap-14  flex justify-center 2xl:flex-row xl:flex-col lg:flex-col flex-col items-center ">
          <div className="left 2xl:w-1/2 xl:w-full lg:w-full w-full">
            <img
              className="floating 2xl:h-[800px] xl:h-[800px] lg:h-[800px] md:h-[800px] h-[400px] w-auto 2xl:w-full  xl:w-auto  lg:w-auto 2xl:mt-10  mt-2"
              src={Chart}
              alt=""
            />
          </div>
          <div className="Right 2xl:w-1/2 xl:w-full lg:w-full w-full mx-auto gap-5  flex-col flex items-center justify-around">
            <div className="card w-full hover:shadow-[#65A6F3] hover:shadow-xl hover:scale-110 transition-all duration-300 2xl:w-[80%] xl:w-full lg:w-full h-auto mt-20">
              <div className="cardtitle bg-gradient-to-b from-[#65A6F3] to-[#7A47DD] w-full h-[80px] flex justify-center gap-3 items-center rounded-t-lg ">
                <img src={Coins} alt="" className="w-auto h-[45px]" />
                <span className="font-bold text-white text-[20px] ">
                  XYZMER Coin Details
                </span>
              </div>
              <div className="cardcontent bg-blue-950 w-full flex flex-col px-4 rounded-b-lg justify-start items-start ">
                <div className="  flex flex-col gap-8 my-10 justify-start items-start text-left">
                  <p className="font-regular text-white text-[14px] ">
                    Name : XYZMER Coin
                  </p>
                  <p className="font-regular text-white text-[14px] ">
                    Total Supply : 666,666,666
                  </p>
                  <p className="font-regular text-white text-[14px] ">
                    Ticker : $XYZMER
                  </p>
                  <p className="font-regular text-white text-[14px] ">
                    Network : Binance Smart Chain (BSC)
                  </p>
                  <p className="font-regular text-white text-[14px] flex flex-col ">
                  Tax Buy : 2% Tax Sell : 5% <span className="italic text-[12px]">(Once the market capitalization reaches $100 million, we will completely eliminate the buy and sell tax. Additionally, developers will not utilize the allocation for research & development and community building while the tax is still in effect.)</span>
                  </p>
                
                  <p className="font-regular text-white text-[14px] ">
                    Token Decimal : 18
                  </p>
                  <a
                  href="https://bscscan.com/address/0xf67d3a33ef4ad4316b2ca6be257a4d79fd2088c2"
                  target="/blank" 
                  className="font-semibold  text-white text-[14px] ">
                    Contract Address : <span className="font-semibold underline text-blue-500 text-[14px]">0xf67d3a33ef4ad4316b2ca6be257a4d79fd2088c2</span>
                  </a>
                  {/* <p className="font-regular text-white text-[14px] -mt-5 inline-flex items-center gap-3">
                    0x2bF4BE7C4520C41d003b898534
                    <button onClick={copyToClipboard}>
                      <MdOutlineContentCopy />
                    </button>
                  </p> */}
                </div>
              </div>
            </div>
            {/* <div className="card hover:shadow-xl hover:shadow-[#F27A76] hover:scale-110 transition-all duration-300 2xl:w-[80%] xl:w-[80%] lg:w-[80%] w-full h-auto mt-5">
                <div className="cardtitle shadow-lg bg-gradient-to-r from-[#F27A76] to-[#A2144E] w-full h-[80px] flex justify-center gap-5 items-center rounded-t-lg ">
                  <span className="font-bold text-white text-[20px] ">
                    5% Tax Alocation
                  </span>
                </div>
                <div className="cardcontent bg-[#0D2C5B] w-full flex flex-col px-6 rounded-b-lg gap-5 py-[74px] ">
                  <div className="card3% w-full shadow-md flex items-center h-[85px] bg-gradient-to-b from-[#D0D7E2] to-[#ADCEFF] rounded-lg">
                    <div className="content1 px-5">
                      <span className="text-[35px] font-bold text-[#0D2C5B]">
                        3%
                      </span>
                    </div>
                    <div className="content2 flex flex-col">
                      <span className="font-bold  text-[#0D2C5B] text-[22px]">
                        Developer
                      </span>
                      <span className="font-regular  w-[85%] text-[#0D2C5B] text-[10px]">
                        It is used for the development & Operational of the
                        movesix system
                      </span>
                    </div>
                  </div>
                  <div className="card4% w-full shadow-md flex items-center h-[85px] bg-gradient-to-b from-[#D0D7E2] to-[#D0E2D6] rounded-lg">
                    <div className="content1 px-5">
                      <span className="text-[35px] font-bold text-[#508819]">
                        4%
                      </span>
                    </div>
                    <div className="content2 flex flex-col">
                      <span className="font-bold  text-[#508819] text-[22px]">
                        Presale Investor
                      </span>
                      <span className="font-regular  w-[95%] text-[#508819] text-[10px]">
                        Whitelist investors, private investors, and Presale
                        investors will receive this reward evenly every month.
                      </span>
                    </div>
                  </div>
                  <div className="card1% w-full flex shadow-md items-center h-[85px] bg-gradient-to-b from-[#D0D7E2] to-[#FFE7F8] rounded-lg">
                    <div className="content1 px-5">
                      <span className="text-[35px] font-bold text-[#A20977]">
                        1%
                      </span>
                    </div>
                    <div className="content2 flex flex-col">
                      <span className="font-bold  text-[#A20977] text-[22px]">
                        NFT MVI Reward
                      </span>
                      <span className="font-regular  w-[95%] text-[#A20977] text-[10px]">
                        Owners of Most Valuable Investors NFTs will receive
                        evenly distributed reward shares.
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tokenomic;
