import React from "react";
import Banner1 from "../../../asset/Bannersm.png";

import NextBtn from "../Component/Button/NextBtn";
import PrevBtn from "../Component/Button/PrevBtn";

const Teams = ({ setOnActive, onActive }) => {
  return (
    <div className="Teamscontent w-full h-full overflow-y-hidden overflow-x-hidden">
      <div className="wraptitles 2xl:w-[90%] w-[90%] mx-auto 2xl:mx-[100px] ">
        <div className="titles w-full flex mt-[30px]">
          <div className="contains w-full gap-3 2xl:mb-12 2xl:mt-4 xl:mb-12 xl:mt-4 lg:mb-12 lg:mt-4 flex flex-col text-left">
            <img src={Banner1} alt="" />

            <p className="text-white  font-bold 2xl:text-[40px] xl:text-[40px] lg:text-[40px] text-[30px]  mt-[60px] ">
              Team and Advisors
            </p>
            <div className="wrap-team w-full flex flex-col justify-center items-start">
              <p className="text-white  font-bold 2xl:text-[26px] xl:text-[26px] lg:text-[26px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px] ">
                XYZMER COIN Core Team
              </p>

              <p className="text-white w-full 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
                The XYZMER COIN core team consists of experienced blockchain
                professionals and experts in the creative industry. Our core
                team members have a deep understanding of the blockchain
                ecosystem and a track record of managing successful projects. We
                are passionate about leveraging blockchain technology to
                revolutionize the creative industry and create innovative
                solutions for royalty-related issues worldwide.
              </p>
            </div>

            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] 2xl:mt-[100px]xl:mt-[100px] lg:mt-[100px] mt-[60px]">
              XYZMER COIN Advisory Team
            </p>
            <p className="text-white w-full 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
              XYZMER COIN has recruited renowned blockchain advisors and
              investors to join our advisory team. These individuals bring
              extensive experience and insights from their respective fields,
              complementing the expertise of our core team.
            </p>
            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] 2xl:mt-[100px]xl:mt-[100px] lg:mt-[100px] mt-[60px]">
              Partnership Relationships
            </p>
            <p className="text-white w-full 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
              XYZMER COIN will establish close partnerships with various
              entities to drive the project’s success. These partnerships
              include technology partners, exchange partners, ecosystem
              partners, media partners, as well as incubators and investment
              institutions.
            </p>

            <div className="wrap w-full h-full 2xl:flex xl:flex lg:flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-center items-center 2xl:gap-10 xl:gap-10 lg:gap-10 ">
              <PrevBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="Community"
                link="/whitepaper/community"
              />
              <NextBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="Security and Compliance"
                link="/whitepaper/security"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
