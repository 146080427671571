import React from "react";
import Banner1 from "../../../asset/Bannersm.png";
import NextBtn from "../Component/Button/NextBtn";
import PrevBtn from "../Component/Button/PrevBtn";

const Listing = ({ setOnActive, onActive }) => {
  return (
    <div className="Listingcontent w-full h-full overflow-y-hidden overflow-x-hidden">
      <div className="wraptitles 2xl:w-[90%] w-[90%] mx-auto 2xl:mx-[100px] ">
        <div className="titles w-full flex mt-[30px]">
          <div className="contains gap-3 2xl:mb-12 2xl:mt-4 xl:mb-12 xl:mt-4 lg:mb-12 lg:mt-4 flex flex-col text-left">
            <img src={Banner1} alt="" />
            <p className="text-white  font-bold 2xl:text-[40px] xl:text-[40px] lg:text-[40px] text-[30px]  mt-[60px] ">
              Listing Plan XYZMER Coin
            </p>

            <p className="text-white  2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]  mb-5">
              Before the listing of XYZMER Coin, we will launch two platforms:
            </p>
            <ul className="list-decimal list-inside  text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] ml-5 ">
              <li>
                <span className="font-bold"> Play to Earn: </span>This platform is designed to build a strong
                community through gaming, where users will receive rewards for
                completing missions and tasks.{" "}
              </li>
              <li className="mt-3">
              <span className="font-bold">Presale: </span> This platform aims to provide early investors with the
                opportunity to contribute in the early stages, with a token
                distribution mechanism that follows a vesting schedule.
              </li>
            </ul>
            <p className="text-white  2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]  my-5">
              Once these platforms have met our predefined targets, XYZMER Coin
              will be listed on decentralized exchanges. Following the grand
              launch of xyznt.io and the completion of 50% of the development
              for our crypto media platform, we will begin pursuing listings on
              major cryptocurrency exchanges to enhance project exposure and
              liquidity.
            </p>
            <div className="wrap w-full h-full 2xl:flex xl:flex lg:flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-center items-center 2xl:gap-10 xl:gap-10 lg:gap-10 ">
              <PrevBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="XYZMER Coin Ecosystem"
                link="/whitepaper/ecosystem"
              />
              <NextBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="Community XYZMER COIN"
                link="/whitepaper/community"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
