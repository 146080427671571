import React, { useState } from "react";
import { FiArrowDownCircle } from "react-icons/fi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Usdt from "../../asset/usdt.png";
import Bnb from "../../asset/BNB.png";

const Pool = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("USDT");
  const [selectedIcon, setSelectedIcon] = useState(Usdt);

  return (
    <div className="container-pool  w-[95%] flex flex-col ">
      <div className="wrap-pool  w-full flex justify-start items-start mt-10 gap-5">
        <button className="text-white font-bold text-[14px] p-2 h-[34px] w-[92px] bg-orange-400 flex justify-center items-center rounded-full  ">
          Funding
        </button>
        <button className="text-white font-bold text-[14px] p-2 h-[34px] w-[92px] border-2 border-orange-400 flex justify-center items-center rounded-full  ">
          Spot
        </button>
      </div>
      <div className="wrap-content  w-full flex justify-center items-center">
        <div className="wrapper   w-[25%] flex flex-col  mt-10">
          <div className="back w-[65%] flex rounded-full justify-start items-start gap-3">
            <button className="text-white rounded-full bg-blue-900 font-sm text-[14px] p-4 w-[120px] h-[45px] flex justify-center items-center ">
              Transfer
            </button>
            <button className="text-white rounded-full border-2 border-blue-900  font-sm text-[14px] p-4 w-[120px] h-[45px] flex justify-center items-center ">
              Withdraw
            </button>
          </div>

          <div className="content w-full h-full bg-blue-900 rounded-xl flex flex-col justify-center items-center mt-5 py-5">
            <div className="title w-full px-8 mb-10 flex flex-col justify-start items-start">
              <p className="text-white font-sm text-[24px] text-left">
                Transfer
              </p>
              <p className="text-gray-400 font-sm text-[14px] text-left">
                Make your own pool
              </p>
            </div>
            <div className="wrap-text-from w-[80%] flex flex-col justify-start items-start text-left">
              <p className="text-white text-[16px]">From</p>
              <span className="w-full flex justify-between items-center rounded-lg px-5 mt-3 text-gray-400 text-[16px] bg-blue-200 h-[48px] ">
                Wallet
              </span>
            </div>
            <div className="arrow py-5">
              <FiArrowDownCircle className="text-white text-[36px] " />
            </div>
            <div className="wrap-text-from w-[80%] flex flex-col justify-start items-start text-left ">
              <p className="text-white text-[16px]">To</p>
              <span className="w-full flex justify-between items-center rounded-lg px-5 mt-3 text-gray-400 text-[16px] bg-blue-200 h-[48px] mb-10 ">
                Funding
              </span>
              <p className="text-white text-[16px]">Currency</p>
              <button
                className="w-full flex justify-between items-center rounded-lg px-5 mt-3 text-black text-[16px] bg-white h-[48px] "
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <div className="wrap-icons flex justify-start items-start gap-2">
                  <img src={selectedIcon} alt="" className="w-[24px] h-auto" />
                  <p className=" text-black font-sm text-[16px]">
                    {selectedOption}
                  </p>
                </div>
                <MdOutlineKeyboardArrowDown />
              </button>
              {dropdownOpen && (
                <div className="popup w-full flex flex-col justify-start items-start rounded-lg  mt-3 text-black text-[16px] bg-white gap-3 transition-all duration-500 ease-in-out">
                  <button
                    onClick={() => {
                      setSelectedOption("USDT");
                      setSelectedIcon(Usdt);
                      setDropdownOpen(false);
                    }}
                    className="wrap-option w-full flex justify-start px-5 py-3 mt-5 items-start gap-3 hover:bg-blue-200 hover:rounded-lg"
                  >
                    <img src={Usdt} alt="USDT" className="w-[24px]  h-auto" />
                    <button>USDT</button>
                  </button>
                  <button
                    onClick={() => {
                      setSelectedOption("BNB");
                      setSelectedIcon(Bnb);
                      setDropdownOpen(false);
                    }}
                    className="wrap-option w-full flex justify-start px-5 py-3 mb-5 items-start gap-3 hover:bg-blue-200 hover:rounded-lg"
                  >
                    <img src={Bnb} alt="BNB" className="w-[24px]  h-auto" />
                    <button>BNB</button>
                  </button>

                  {/* Add more options as needed */}
                </div>
              )}
            </div>
            {/* <div className="wrap-input-from w-full flex justify-center items-center">
        <input
        type="number" 
        className=" w-[70%] h-10 p-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" />
        </div> */}
          </div>
          <div className="content w-full h-[40%] bg-blue-900 rounded-xl flex flex-col justify-center items-center mt-5 py-10">
            <div className="wrap-text-to w-[70%] flex flex-col justify-start items-start text-left"></div>

            {/* <div className="wrap-input-from w-full flex justify-center items-center">
        <input
        type="number" 
        className=" w-[70%] h-10 p-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" />
        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pool;
