import {useState, useEffect} from 'react'
import BN1 from "../../asset/Banner5.jpg";
import BN2 from "../../asset/Banner4@2x.jpg";
import BN3 from "../../asset/Banner6.jpg";
import BN4 from "../../asset/Banner5@2x.jpg";
import BN5 from "../../asset/BannerMedia.png";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import { CSSTransition } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';
import "./Hero.css";

const Hero = () => {
  const [slide, setSlide] = useState(1);
  const [swipeDirection, setSwipeDirection] = useState('left');

  const handleButtonClick = (linkName) => {
    let externalLink;
    if (linkName === "pck") {
      externalLink = "https://t.me/Xyzmercoin_bot "; 
    } else if (linkName === "dext") {
      externalLink = "https://www.dextools.io/app/en/bnb/pair-explorer/0xce6160bb594fc055c943f59de92cee30b8c6b32c?t=1716170100955"; 
    }
     else if (linkName === "saintio") {
      externalLink = "https://xyznt.io/"; 
    }
    window.open(externalLink, "_blank");
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setSlide(prevSlide => prevSlide ===5 ? 1 : prevSlide + 1);
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  const handleNext = () => {
    setSlide((prevSlide) => (prevSlide === 5 ? 1 : prevSlide + 1));
    setSwipeDirection('left');
  };
  
  const handlePrev = () => {
    setSlide((prevSlide) => (prevSlide === 1 ? 5 : prevSlide - 1));
    setSwipeDirection('right');
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleNext();
      setSwipeDirection('left');
    },
    onSwipedRight: () => {
      handlePrev();
      setSwipeDirection('right');
    },
  });

  return (
    <section id='home' className="Hero w-full">
          
  <div {...handlers} className="relative w-full 2xl:h-[730px] xl:h-[370px] lg:h-[370px] md:h-[330px] sm:-[330px] h-[150px] overflow-hidden">
    <CSSTransition
      in={slide === 1}
      timeout={1000}
      classNames={`slide-${swipeDirection}`}
      unmountOnExit
      onClick={() => handleButtonClick("saintio")}
      pointer-events="auto"
      
    >
      <img className="clickable absolute w-full h-full object-fill" src={BN4} alt="" />
    </CSSTransition>
    <CSSTransition
      in={slide === 2}
      timeout={1000}
      classNames={`slide-${swipeDirection}`}
      unmountOnExit
    pointer-events="auto"
      
    >
      <img  className="clickable absolute w-full h-full object-fill " src={BN3} alt="" />
    </CSSTransition>
    <CSSTransition
      in={slide === 3}
      timeout={1000}
      classNames={`slide-${swipeDirection}`}
      unmountOnExit
      pointer-events="auto"
    >
      <img className="clickable absolute w-full h-full object-fill" src={BN5} alt="" />
    </CSSTransition>
    <CSSTransition
      in={slide === 4}
      timeout={1000}
      classNames={`slide-${swipeDirection}`}
      unmountOnExit
      pointer-events="auto"
      onClick={() => handleButtonClick("pck")}
    >
      <img className="clickable absolute w-full h-full object-fill" src={BN1} alt="" />
    </CSSTransition>
    <CSSTransition
      in={slide === 5}
      timeout={1000}
      classNames={`slide-${swipeDirection}`}
      unmountOnExit
      pointer-events="auto"
      
    >
      <img className="clickable absolute w-full h-full object-fill" src={BN2} alt="" />
    </CSSTransition>
  </div>

          <div className="buttonsSlide text-white 2xl:text-3xl xl:text-3xl lg:text-3xl text-md w-full flex items-center gap-14 justify-center my-5">
            <button onClick={handlePrev}> <IoIosArrowDropleftCircle/>  </button>
            <button onClick={handleNext}> <IoIosArrowDroprightCircle/></button>
          </div>
        </section>
  )
}

export default Hero