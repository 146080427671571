import React from 'react'
import Faqq from "../PageComponent/Faq/Faq";
import Footer from "../Component/Footer/Footer"


const Faq = () => {
  return (
    <div className='p2p flex-col 2xl:mt-[80px] xl:mt-[80px] lg:mt-[80px] mt-[60px] w-full flex justify-center items-center'>
        <div className="wrapperhome w-full flex justify-center items-center">
        <Faqq/>
        
        </div>
        <Footer/>
    </div>
  )
}

export default Faq