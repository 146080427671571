import React from 'react'
import Pool from '../PageComponent/P2p/Pool'

const P2p = () => {
  return (
    <div className='p2p 2xl:mt-[80px] xl:mt-[80px] lg:mt-[80px] mt-[60px] w-full flex justify-center items-center'>
        <div className="wrapperhome w-full flex justify-center items-center">
        <Pool/>
        </div>
    </div>
  )
}

export default P2p