import React from "react";
import Banner1 from "../../../asset/Bannersm.png";
import NextBtn from "../Component/Button/NextBtn";
import PrevBtn from "../Component/Button/PrevBtn";

const Risk = ({setOnActive, onActive}) => {
  return (
    <div className="riskcontent w-full h-full overflow-y-hidden overflow-x-hidden">
      <div className="wraptitles 2xl:w-[90%] w-[90%] mx-auto 2xl:mx-[100px] ">
        <div className="titles w-full flex mt-[30px]">
          <div className="contains gap-3 2xl:mb-12 2xl:mt-4 xl:mb-12 xl:mt-4 lg:mb-12 lg:mt-4 flex flex-col text-left">
            <img src={Banner1} alt="" />
            <p className="text-white  font-bold 2xl:text-[40px] xl:text-[40px] lg:text-[40px] text-[30px]  mt-[60px] ">
              Risk Disclosure
            </p>
            <p className="text-white  font-bold 2xl:text-[26px] xl:text-[26px] lg:text-[26px] text-[20px] mt-[10px] ">
              Understanding Potential Risks in XYZMER COIN Investment
            </p>
            

            <p className="text-white w-full 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] ">
            Before engaging in XYZMER COIN investments, it is important to consider market risks, technological risks, legal and compliance risks, execution risks, and crowdfunding risks. Investors should conduct independent risk assessments and make decisions based on their own situations.
            </p>
            
            <div className="wrap w-full h-full 2xl:flex xl:flex lg:flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-center items-center 2xl:gap-10 xl:gap-10 lg:gap-10 ">
              <PrevBtn onActive={onActive} setOnActive={setOnActive} label="Security and Compliance" link="/whitepaper/security" />
              <NextBtn onActive={onActive} setOnActive={setOnActive} label="Conclution" link="/whitepaper/conclution" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Risk;
