import { useState, useEffect } from "react";
import Logo from "../../asset/XYZMER LOGOs.png";
import { Spiral as Hamburger } from "hamburger-react";
import { CSSTransition } from "react-transition-group";
import { IoMdArrowDropright } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [scrollShow, setScrollShow] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollTimer, setScrollTimer] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false)

  
const handleDropdownMouseEnter = () => {
  setIsDropdownOpen(true); 
};


const handleDropdownMouseLeave = () => {
  setIsDropdownOpen(false); 
};


  const handleDropdownItemClick = (itemName) => {
    

    let externalLink;
    
    if (itemName === "Option 1") {
      externalLink = "https://xyzonemedia.com/technology/"; 
    } else if (itemName === "Option 2") {
      externalLink = "https://www.xyznt.io"; 
    } else if (itemName === "Option 3") {
      externalLink = " http://192.168.100.32:3001"; 
    }
    else if (itemName === "Option 4") {
      externalLink = "http://192.168.100.92:3000"; 
    }

    // Buka tautan eksternal di tab baru
    window.open(externalLink, "_blank");
    console.log(`Selected item: ${itemName}`);
    
  };

  const handleButtonClick = (linkName) => {
    let externalLink;
    if (linkName === "pck") {
      externalLink = "https://t.me/Xyzmercoin_bot/play "; 
    } else if (linkName === "dext") {
      externalLink = "https://www.dextools.io/"; 
    }
    window.open(externalLink, "_blank");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimer !== null) {
        clearTimeout(scrollTimer);
      }

      setScrollTimer(
        setTimeout(() => {
          const st = window.pageYOffset || document.documentElement.scrollTop;
          if (st > lastScrollTop) {
            // downscroll code
            setScrollShow(false);
          } else {
            // upscroll code
            setScrollShow(true);
          }
          setLastScrollTop(st <= 0 ? 0 : st); // For Mobile or negative scrolling
        }, 200)
      ); // 200ms delay
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop, scrollTimer]);

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      setNavOpen(false);
    } else {
      console.warn(`Element with id "${id}" not found`);
    }
  };

  return (
    <>
      <nav
        className={`z-[1000] fixed top-0 left-0 w-full flex-col flex justify-center items-center bg-white/30 backdrop-blur-lg transition-all duration-500 ${
          scrollShow ? "opacity-1" : "opacity-0"
        }`}
      >
        <div className="w-full relative 2xl:h-[80px] xl:h-[80px] lg:h-[80px] h-[60px] flex justify-between items-center 2xl:px-10 xl:px-10 lg:px-10 px-3 py-2">
          <div className="wrap left w-full flex justify-start items-center gap-3">
          <Link to="/"> 
            <button
            onClick={() => handleScrollTo("home")}
            className="flex justify-center items-center gap-2">
             
              <img
              
                
                src={Logo}
                alt=""
                className="w-[40px] h-auto "
              />
              
              <p className="font-extrabold 2xl:text-xl xl:text-xl lg:text-xl md:text-sm text-sm text-white">XYZMER COIN</p>
            </button>
            </Link>
           
          </div>
          <div className="wrap right w-full  2xl:block xl:block lg:block hidden ">
            <div className="menu gap-10 items-center justify-end flex">
              <Link to="/" 
                onClick={() => handleScrollTo("home")}
                className="text-white font-semibold hover:text-orange-400"
              >
                Home
              </Link>
              <Link
                to="/"
                onClick={() => handleScrollTo("tokenomic")}
                className="text-white font-semibold hover:text-orange-400"
              >
                Tokenomics
              </Link>
              <Link
                to="/"
                onClick={() => handleScrollTo("roadmap")}
                className="text-white font-semibold hover:text-orange-400"
              >
                Roadmap
              </Link>
              <Link
                to="/"
                onClick={() => handleScrollTo("community")}
                className="text-white font-semibold hover:text-orange-400"
              >
                Community
              </Link>
              
              <div className="dropdown relative inline-block text-left">
                <button
                  onMouseEnter={handleDropdownMouseEnter}
                 
                  className="text-white font-semibold hover:text-orange-400 flex justify-center items-center "
                >
                  Ecosystem <IoMdArrowDropright className="text-[25px]" />
                </button>
                {isDropdownOpen && (
                  <div
                  onMouseLeave={handleDropdownMouseLeave}
                  className={`origin-top-right absolute gap-10 left-0 mt-6 w-[250px] rounded-md shadow-lg bg-[#0D2C5B]  ring-1 ring-black ring-opacity-5 dropdown-menu dropdown-animation ${
                    isDropdownOpen ? "open" : ""
                  }`}
                >
                    <div
                      className="py-4 px-3 gap-5 flex flex-col"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {/* Daftar pilihan dropdown */}
                      <button
                        // onClick={() => handleDropdownItemClick("Option 1")}
                        className="block px-4 py-2 text-sm text-white hover:text-orange-400 w-full text-left"
                        role="menuitem"
                      >
                        XYZone Media
                      </button>
                      <button
                        onClick={() => handleDropdownItemClick("Option 2")}
                        className="block px-4 py-2 text-sm text-white hover:text-orange-400 w-full text-left"
                        role="menuitem"
                      >
                        Saintio NFT Marketplace
                      </button>
                      <button
                        // onClick={() => handleDropdownItemClick("Option 3")}
                        className="block px-4 py-2 text-sm text-white hover:text-orange-400 w-full text-left"
                        role="menuitem"
                      >
                        XYZMER Swap
                      </button>
                     
                    </div>
                  </div>
                )}
              </div>
              {/* <button
                    onClick={() => handleButtonClick("dext")}
                    className="text-white font-semibold hover:text-orange-400 flex justify-center items-center gap-2"
                  >
                    <img className="w-auto h-[30px]" src={Coins} alt="" />
                    <span>$0.004134</span>
                  </button> */}

              <div className="buttonact 4xl:w-[240px] 2xl:w-[180px] xl:w-[180px] lg:w-[180px]  2xl:h-[45px] xl:h-[45px] lg:h-[45px] flex">
                <button className="btn flex justify-center items-center gap-2 font-extrabold " onClick={() => handleButtonClick("pck")}>
                  Play to earn <MdKeyboardArrowRight />
                </button>
              </div>
            </div>
          </div>
          <div className="hamburger 2xl:hidden xl:hidden lg:hidden block">
            <Hamburger
              color="#FFF"
              toggled={navOpen}
              toggle={setNavOpen}
              size={20}
            />
          </div>
          <CSSTransition
            in={navOpen}
            timeout={500}
            classNames="menu"
            unmountOnExit
          >
            <div className="menu absolute z-10 top-[60px] left-0 gap-10 items-center flex flex-col bg-[#01062E]/90 w-full h-screen ">
              <div className="menu-wrapp items-center w-full flex flex-col justify-between py-24 ">
                <div className="menuItem flex flex-col gap-10 px-10 items-center">
                  <Link to="/">
                  <button
                    onClick={() => handleScrollTo("home")}
                    className="text-white font-semibold hover:text-orange-400"
                  >
                    Home
                  </button>
                  </Link>
                  <Link to="/">
                  <button
                    onClick={() => handleScrollTo("tokenomic")}
                    className="text-white font-semibold hover:text-orange-400"
                  >
                    Tokenomics
                  </button>
                  </Link>
                  <Link to="/">
                  <button
                    onClick={() => handleScrollTo("roadmap")}
                    className="text-white font-semibold hover:text-orange-400"
                  >
                    Roadmap
                  </button>
                  </Link>
                  <Link to="/">
                  <button
                    onClick={() => handleScrollTo("community")}
                    className="text-white font-semibold hover:text-orange-400"
                  >
                    Community
                  </button>
                  </Link>
                  <div className="hamburger block 2xl:hidden xl:hidden lg:hidden">
                    <button
                    onClick={() => setShowMenu(!showMenu)}

                      className="text-white w-full h-10 font-semibold hover:text-orange-400 flex justify-center items-center"
                    >
                      
                      Ecosystem <IoMdArrowDropright className="text-[25px]" />
                    </button>
                    {showMenu && (
                  <div
                
                  className=" gap-10  rounded-md w-full"
                >
                    <div
                      className="py-4 px-3 gap-5 flex flex-col"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {/* Daftar pilihan dropdown */}
                      <button
                        // onClick={() => handleDropdownItemClick("Option 1")}
                        className="block px-4 py-2 text-sm text-slate-400 hover:text-orange-400 "
                        
                      >
                        XYZone Media
                      </button>
                      <button
                        onClick={() => handleDropdownItemClick("Option 2")}
                        className="block px-4 py-2 text-sm text-slate-400 hover:text-orange-400 "
                        
                      >
                        Saintio NFT Marketplace
                      </button>
                      <button
                        // onClick={() => handleDropdownItemClick("Option 3")}
                        className="block px-4 py-2 text-sm text-slate-400 hover:text-orange-400 "
                        
                      >
                        XYZMER Swap
                      </button>
                      
                      
                    </div>
                  </div>
                )}

                
                      
                   
                  </div>
                  {/* <button
                    onClick={() => handleButtonClick("dext")}
                    className="text-white font-semibold hover:text-orange-400 flex justify-center items-center gap-2"
                  >
                    <img className="w-auto h-[30px]" src={Coins} alt="" />
                    <span>$0.004134</span>
                  </button> */}

                  <div className="buttonact w-[180px] h-[45px] flex">
                    <button
                      className="btn font-bold flex justify-center items-center gap-2"
                      onClick={() => handleButtonClick("pck")}
                    >
                      Play to earn <MdKeyboardArrowRight />
                    </button>
                  </div>
                </div>
                <div className="wraplogo flex justify-center items-center gap-4 ">
                <img src={Logo} alt="" className="h-10" />
                <p className="font-extrabold text-xl text-white">XYZMER COIN</p>
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
