import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GoArrowLeft } from "react-icons/go";

const PrevBtn = ({label, link, onActive, setOnActive }) => {
const navigate = useNavigate();
const handleNext = (path) => {
  
  
  navigate(path); 
  

  if(onActive === "/"){
    setOnActive(false)
  } else {
    setOnActive(path)
  } 

  window.scrollTo(0, 0);
  }
  
    
  return (
    <button onClick= {() => handleNext(link)} className="left 2xl:w-1/2 xl:w-1/2 w-full gap-3 rounded-2xl px-5 2xl:py-5 xl:py-5 lg:py-5 py-5 border-[1px] border-white 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[40px] mb-[20px] flex justify-between items-center">
          <div className="text-white text-lg px-3 "><GoArrowLeft /></div>
          <div className="flex items-end justify-end flex-col gap-3 px-3">
            <p className="text-white font-light 2xl:text-sm xl:text-sm lg:text-sm text-xs">Previous</p>
            <p className="text-white font-bold 2xl:text-lg xl:text-lg lg:text-lg text-sm ">{label}</p>
            
            

          </div>
          
          
          </button>
  )
}

export default PrevBtn