import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Roadmap = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
    });
  }, []);
  return (
    <section id='roadmap' data-aos="fade-up" data-aos-delay="200"  className="Rodamap w-full p-5 justify-center items-center flex 2xl:mt-[120px] xl:-mt[70px] lg:-[70px] mt-[20px] flex-col">
        <div className="titleroadmap mt-10">
          <span className="text-white font-bold 2xl:text-[56px] xl:text-[50px] lg:text-[50px] text-[24px]">Roadmap</span>
        </div>
        <div className="contRoadMap relative h-auto flex flex-col justify-center items-center w-full 2xl:py-[120px] xl:py-[70px] lg:py-[70px] py-[70px] 2xl:flex-row  2xl:justify-center 2xl:items-center 2xl:flex gap-10 ">
          {/* <span className="w-[4px] bg-white h-[1300px] xl:mt-[150px] lg:mt-[150px] 2xl:hidden xl:block lg:block block"/>
          <span className="h-[8px] bg-white 2xl:mt-0  w-[65%] 2xl:block xl:hidden lg:hidden hidden"/> */}
          <div className="cards-roadmap 2xl:w-full xl:w-full lg:w-full flex 2xl:flex-row xl:flex-col lg:flex-col flex-col gap-12 justify-center items-center">
          <div className="card2 hover:shadow-2xl hover:scale-110 transition-all duration-300 w-[330px] h-[400px] flex flex-col items-center bg-gradient-to-t from-[#DED2F3] to-[#FFF] rounded-xl">
            <div className="wrapboxfront flex w-[60%] -translate-y-5 -translate-x-[86px]">
              <div className="card title bg-gradient-to-r from-[#60BBF8] to-[#7C3EDB] w-full h-[60px] ">
                <span className="flex justify-center items-center w-full py-4 font-bold text-white text-[18px]">
                  Phase 1 (Q4-2024)
                </span>
              </div>
            </div>

            <div className="box rounded-xl justify-center items-center flex flex-col pt-1 pb-5">
              <div className="content-wrap px-[40px] w-[330px] ">
                <ul className="marker:text-slate-400 list-disc space-y-2 text-slate-400 text-left ">
                  <li className="text-[#794ADE] marker:text-[#794ADE] ">
                    Whitepaper 1.0 Release
                  </li>
                  
                  <li className="text-[#794ADE] marker:text-[#794ADE]">XYZMER Play to earn (P2E)</li>
                  <li className="text-[#794ADE] marker:text-[#794ADE]">Community Building</li>
                  <li className="text-[#794ADE] marker:text-[#794ADE] font-bold marker:animate-ping  duration-700 transition-all">XYZnt.io Beta Release</li>
                  <li>Presale XYZMER Coin</li>
                  <li>XYZnt.io Launching</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card2 hover:shadow-2xl hover:scale-110 transition-all duration-300 w-[330px] h-[400px] flex flex-col items-center bg-gradient-to-t from-[#E8CDCF] to-[#FFF] rounded-xl">
            <div className="wrapboxfront flex w-[60%] -translate-y-5 -translate-x-[86px]">
              <div className="card title bg-gradient-to-r from-[#F27A76] to-[#A2144E] w-full h-[60px] ">
                <span className="flex justify-center items-center w-full py-4 font-bold text-white text-[18px]">
                  Phase 2 (Q1-2025)
                </span>
              </div>
            </div>

            <div className="box rounded-xl justify-center items-center flex flex-col pt-1 pb-5">
              <div className="contentWrap px-[40px]">
                <ul className="marker:text-slate-400 list-disc space-y-2 text-slate-400 text-left">
                  <li>Smart Contract Audit (Certik)</li>
                  <li>Listing on PancakeSwap, CoinGecko, Coinmarketcap </li>
                  <li>Marketing & Campaign for XYZMER</li>
                  <li>Web3 Wallet Integration</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card2 hover:shadow-2xl hover:scale-110 transition-all duration-300 w-[330px] h-[400px] flex flex-col items-center bg-gradient-to-t from-[#EFE3D9] to-[#FFF] rounded-xl">
            <div className="wrapboxfront flex w-[60%] -translate-y-5 -translate-x-[86px]">
              <div className="card title bg-gradient-to-r from-[#FFBA76] to-[#FF734D] w-full h-[60px] ">
                <span className="flex justify-center items-center w-full py-4 font-bold text-white text-[18px]">
                  Phase 3 (Q2-2025)
                </span>
              </div>
            </div>

            <div className="box  rounded-xl justify-center items-center flex flex-col pt-1 pb-5">
              <div className="contentWrap px-[40px]">
                <ul className="marker:text-slate-400 list-disc space-y-2 text-slate-400 text-left ">
                  <li>Exchanger Listing on gate.io </li>   
                  <li>
                  Marketing Agreements for Wider Audience
                  </li>
                  <li>
                  Investor Relations & Partnerships with Major Investment Channels
                  </li>
                  <li>
                  Media Crypto Web3 Development
                  </li>
                  <li>Research Blockchain development with Hybird Consensus (Proof of Stake Authority)
</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card2 hover:shadow-2xl hover:scale-110 transition-all duration-300 w-[330px] h-[400px] flex flex-col items-center bg-gradient-to-t from-[#C8EBE1] to-[#FFF] rounded-xl">
            <div className="wrapboxfront flex w-[60%] -translate-y-5 -translate-x-[86px]">
              <div className="card title bg-gradient-to-r from-[#16D799] to-[#57A7AA] w-full h-[60px] ">
                <span className="flex justify-center items-center w-full py-4 font-bold text-white text-[18px]">
                  Phase 4 (Q3-2025)
                </span>
              </div>
            </div>
            <div className="box  rounded-xl justify-center items-center flex flex-col pt-1 pb-5">
              <div className="contentWrap px-[40px]">
                <ul className="marker:text-slate-400 list-disc space-y-2 text-slate-400 text-left">
                <li>Exchanger Listing on Bybit </li>             
                  <li>Initial Blockchain Development</li>
                  <li>Blockchain Development</li>
                  <li>Roadmap Update</li>
                </ul>
              </div>
            </div>
          </div>
          </div>
        
        </div>
        </section>
  )
}

export default Roadmap