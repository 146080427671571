
// import Tokenomic from "../../Asset/KOIN MOVESIX.png"
import { Notes } from "../iconSVG/IconSVG"
import "../iconSVG/IconSVG.css"



export const sideButton = [
    {
        icon: <Notes className="button stroke-white stroke-2 group-hover:stroke-orange-400"/>,
        title: "Token Economic",
        link: "/whitepaper/tokenomic"
        
    },
    {
        title: "Features and Innovation",
        link: "/whitepaper/features"
        
    },
    {
        
        title: "The Ecosystem",
        link: "/whitepaper/ecosystem"
        
    },
    {
        title: "Listing Plan ",
        link: "/whitepaper/listing"
        
    },
    {
        title: "Community ",
        link: "/whitepaper/community"
        
    },
    {
        
        title: "Team and Advisors",
        link: "/whitepaper/teams"
        
    },
    
    {
        title: "Security And Compliance",
        link: "/whitepaper/security"
        
    },
    {
       title: "Risk Disclosure",
        link: "/whitepaper/risk"
        
    },
    {
        
        title: "Conclution",
        link: "/whitepaper/conclution"
        
    },
]
