import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  FaInstagram,
  
  FaMedium,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa6";
import { IoIosArrowRoundForward } from "react-icons/io";
import { BsTwitterX } from "react-icons/bs";
import Telecom from "../../asset/Telecom.png";

import "./Community.css";

const Community = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
    });
  }, []);
  return (
    <section
      data-aos="fade-up"
      data-aos-delay="600"
      id="community"
      className="contcommunity w-full flex flex-col items-center py-20 mt-10"
    >
      <div className="wrapcom hover:shadow-[#65A6F3]  bg-[#1A245A] 2xl:w-[60%] w-[90%] 2xl:h-[340px] h-[380px] rounded-2xl flex flex-col justify-center items-center  ">
        <span className="text-white 2xl:text-[28px] font-md font-medium text-[18px] mt-10 ">
          Join Our Community
        </span>
        <div className="warpcard1 flex flex-col justify-center items-center  w-full h-full gap-4 2xl:flex-row 2xl:mb-6">
          <a
            href="https://t.me/xyzmercoin_Channel"
            target="_blank"
            rel="noopener noreferrer"
            className="left hover:shadow-xl hover:scale-110 transition-all duration-300 px-3 bg-gradient-to-t from-[#BBD6E4] to-[#FFF] 2xl:w-[30%] w-[80%] h-[70px] 2xl:h-[85px] flex justify-between items-center  rounded-xl "
          >
            <div className="left flex gap-4 items-center">
              <img className="w-[50px] pl-1 h-auto py-3" src={Telecom} alt="" />
              <div className="content2 flex flex-col items-start">
                <span className="2xl:text-[20px] text-[18px] font-bold "> Telegram</span>
                <span className="text-sm 2xl:text-[16px] xl:text-[16px] lg:text-[16px] text-[8.9px]  underline">
                  t.me/xyzmercoin_Channel
                </span>
              </div>
            </div>
            <div className="icons text-2xl text-gray-800">
              <IoIosArrowRoundForward />
            </div>
          </a>
          <a
            href= "https://x.com/XyzmerCoin?t=AoFukEkDMlXbMHawMpK5DA&s=09"
            target="_blank"
            rel="noopener noreferrer"
            className="Right hover:shadow-xl hover:scale-110 transition-all duration-300 px-3 bg-gradient-to-t from-[#B3C3EF] to-[#FFF] 2xl:w-[30%] w-[80%] h-[70px] 2xl:h-[85px] flex justify-between items-center  rounded-xl "
          >
            <div className="left flex gap-4 items-center">
              <BsTwitterX className="w-[50px] pl-3 h-auto  " />
              <div className="content2 flex flex-col items-start">
                <span className="2xl:text-[20px] text-[18px] font-bold ml-1"> X Twitter</span>
                <span className="text-sm ml-1 2xl:text-[16px] xl:text-[16px] lg:text-[16px] text-[8.9px] underline">
                  https://x.com/XyzmerCoin
                </span>
              </div>
            </div>
            <div className="icons text-2xl text-gray-800">
              <IoIosArrowRoundForward />
            </div>
          </a>
        </div>
        <div className="wrapsos justify-center flex flex-col items-center -mt-7 mb-5 gap-5">
          <span className="text-white font-regular ">Find us here :</span>
          <div className="iconwrap flex justify-center items-center gap-5 mb-3">
            <a
              href="https://www.instagram.com/xyzmercoinofficial?igsh=aGxnOHZ0aTI0eTY2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="w-[20px] h-auto hover:shadow-xl hover:scale-150 transition-all duration-300 text-white" />
            </a>
            
            {/* <a href="/" target="_blank" rel="noopener noreferrer"> */}
            <FaMedium className="w-[20px] hover:shadow-xl hover:scale-150 transition-all duration-300 h-auto text-white" />
            {/* </a> */}
            <a href="https://www.tiktok.com/@xyzmercoin" target="_blank" rel="noopener noreferrer">
            <FaTiktok className="w-[20px] hover:shadow-xl hover:scale-150 transition-all duration-300 h-auto text-white" />
            </a>
            <a href="https://www.youtube.com/@XyzmerCoin" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="w-[20px] hover:shadow-xl hover:scale-150 transition-all duration-300 h-auto text-white" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Community;
