import React from "react";
import Banner1 from "../../../asset/Bannersm.png";

import NextBtn from "../Component/Button/NextBtn";
import PrevBtn from "../Component/Button/PrevBtn";

const Tokenomic = ({ setOnActive, onActive }) => {
  return (
    <div className="Tokenomiccontent w-full h-full overflow-y-hidden overflow-x-hidden">
      <div className="wraptokenomic 2xl:w-[90%] w-[90%] mx-auto 2xl:mx-[100px] ">
        <div className="titles w-full flex mt-[30px]">
          <div className="contains gap-1 2xl:mb-12 2xl:mt-4 xl:mb-12 xl:mt-4 lg:mb-12 lg:mt-4 flex flex-col text-left">
            <img src={Banner1} alt="" />
            <p className="text-white  font-bold 2xl:text-[40px] xl:text-[40px] lg:text-[40px] text-[30px] 2xl:mt-[100px]xl:mt-[100px] lg:mt-[100px] mt-[30px] ">
              Coin Symbol and Contract
            </p>
            <p className="text-white font-bold 2xl:text-[26px] xl:text-[26px] lg:text-[26px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px] ">
              Coin Symbol
            </p>
            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
              $XYZMER
            </p>
            <p className="text-white font-bold 2xl:text-[26px] xl:text-[26px] lg:text-[26px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px] ">
              Coin Contract Address:
            </p>
            <a
              href="https://bscscan.com/address/0xf67d3a33ef4ad4316b2ca6be257a4d79fd2088c2"
              target="/blank"
              className="underline text-blue-500 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]"
            >
              0xf67d3a33ef4ad4316b2ca6be257a4d79fd2088c2
            </a>
            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px]">
              Network:
            </p>
            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px]">
              BSC (Binance Smart Chain)
            </p>
            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] mt-[60px] ">
              XYZMER has a total supply of 666,666,666 Coins.
            </p>
            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[16px]  mb-5">
              According to the allocation plan:
            </p>
            <ul className="list-disc text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] ml-5 ">
              <li>Total Supply: 666,666,666</li>
              <li>Token Generation Event (TGE): 33.5%</li>
              <li>Play to earn: 33.5%</li>
              <li>Exchanger: 26.0%</li>
              <li>Community Building: 5.0%</li>
              <li>Research and Development : 2.0%</li>
            </ul>
            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] 2xl:w-full xl:w-[50%] lg:w-[50%] w-full 2xl:mt-[70px] xl:mt-[70px] lg:mt-[70px] mt-[20px] mb-[10px]">
              (Once the market capitalization reaches $100 million, we will
              completely eliminate the buy and sell tax. Additionally,
              developers will not utilize the allocation for research &
              development and community building while the tax is still in
              effect.)
            </p>
            <p className="text-white 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] 2xl:w-full xl:w-[50%] lg:w-[50%] w-full 2xl:mt-[10px] xl:mt-[10px] lg:mt-[10px] mt-[10px] mb-[20px]">
              We have chosen Binance Smart Chain as the network for XYZMER COIN,
              providing efficient transaction speeds and low transaction fees.
              It is also connected to the Binance ecosystem and many DeFi
              applications, offering more opportunities and growth potential for
              XYZMER COIN development
            </p>
            <p className="text-white font-bold 2xl:text-[28px] xl:text-[28px] lg:text-[28px] text-[20px] mb-5 mt-10 ">
              Smart Contract Function
            </p>
            <div className="smartcontract fungtion w-full h-[600px] overflow-y-scroll flex justify-start items-start border-1 border-blue-950 bg-white p-4">
              <div className="2xl:text-[16px]  text-[14px] font-white flex-col flex">
                <div className="">
                  <p className="font-bold">MaxWalletSize (uint256)</p>
                  <p className="">
                    Function: The developer can set the maximum wallet size
                    percentage from the total supply of XYZMER Coin tokens.
                    Purpose: The developer can set a maximum wallet size limit
                    to prevent hoarding of XYZMER Coin tokens.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    AddBotToBlacklist(address account)
                  </p>
                  <p className="">
                    Function: The developer can block accounts suspected of
                    being bots from conducting fraudulent transactions. Purpose:
                    An automated security system aims to blacklist wallet
                    addresses suspected of being bots, thereby preventing fraud
                    in the XYZMER Coin market.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    Approve(address spender, uint256 amount)
                  </p>
                  <p className="">
                    Function: Users can authorize an application to spend their
                    tokens. Purpose: Users grant permission to an exchange
                    application to spend XYZMER tokens from their wallet.
                    Example: Users purchase NFTs on xyznt.io using XYZMER Coin
                    tokens.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">Burn(uint256 amount)</p>
                  <p className="">
                    Function: The developer can reduce the total supply of
                    XYZMER tokens by burning a certain amount of tokens.
                    Purpose: The developer burns XYZMER tokens, reducing the
                    supply, which in turn decreases the market cap and increases
                    the token value as new users purchase XYZMER Coin in the
                    market. decreaseAllowance(address spender, uint256
                    subtractedValue)
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    DecreaseAllowance(address spender, uint256 subtractedValue)
                  </p>
                  <p className="">
                    Function: Users can reduce the amount of tokens allowed to
                    be spent by an application. Purpose: Users reduce the
                    exchange application's spending allowance from value A to
                    value B of XYZMER tokens. Example: A user initially
                    purchases NFT A on xyznt.io for 150 XYZMER tokens, then
                    purchases NFT B for 50 XYZMER tokens, so the allowance,
                    initially set at 150 XYZMER, needs to be reduced to 50
                    XYZMER tokens using this function.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">ExcludeFromFee(address account)</p>
                  <p className="">
                    Function: The developer can exclude an account from
                    transaction fees. Purpose: If the developer partners with a
                    major entity or large investor, the developer can exclude
                    that entity/investor’s account from transaction fees as an
                    incentive.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">ExcludeFromLimit(address account)</p>
                  <p className="">
                    Function: The developer can exclude an account from the
                    maximum transaction limit. Purpose: The developer team's
                    accounts are excluded from the maximum transaction limit for
                    flexibility in fund management.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    ExcludeFromReward(address account)
                  </p>
                  <p className="">
                    Function: The developer can exclude an account from
                    receiving rewards. Purpose: The developer team’s accounts
                    are excluded from rewards to prevent unfair inflation.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">IncludeInFee(address account)</p>
                  <p className="">
                    Function: The developer can re-include an account that was
                    previously excluded from transaction fees. Purpose: The
                    developer can return a user’s account to the list that
                    incurs transaction fees.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">IncludeInLimit(address account)</p>
                  <p className="">
                    Function: The developer can re-include an account that was
                    previously excluded from the maximum transaction limit.
                    Purpose: The developer can return a user’s account to the
                    list that incurs the maximum transaction limit.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">IncludeInReward(address account)</p>
                  <p className="">
                    Function: The developer can re-include an account that was
                    previously excluded from receiving rewards. Purpose: The
                    developer can return a user’s account to the list of reward
                    recipients.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    IncreaseAllowance(address spender, uint256 addedValue)
                  </p>
                  <p className="">
                    Function: Users can increase the number of tokens allowed to
                    be spent by an application. Purpose: Users can increase the
                    exchange application's spending allowance from 100 to 150
                    XYZMER tokens. Example: A user initially purchases NFT A on
                    xyznt.io for 100 XYZMER tokens, then purchases NFT B for 150
                    XYZMER tokens, so the allowance, initially set at 100
                    XYZMER, needs to be increased to 150 XYZMER tokens using
                    this function.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    RemoveBotFromBlacklist(address account)
                  </p>
                  <p className="">
                    Function: The developer can remove an account wrongly
                    flagged as a bot from the blacklist. Purpose: The developer
                    can remove a wallet address from the blacklist after
                    verifying that it is not a bot.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">RenounceOwnership</p>
                  <p className="">
                    Function: renounceOwnership is a method in a smart contract
                    that allows the contract owner (often the creator or
                    developer of the contract) to relinquish ownership of the
                    contract. Once this function is called, the contract owner
                    no longer has any control or special privileges in the
                    contract. Purpose: The purpose of the renounceOwnership
                    function is to decentralize the control of the smart
                    contract.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    SetBuyFee(uint16 tax, uint16 marketing)
                  </p>
                  <p className="">
                    Function: The developer can set the token purchase fee to
                    fund development and marketing. Purpose: The developer can
                    set a token purchase fee of 2% for development and marketing
                    purposes.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    SetMaxTxPercent(uint256 maxTxPercent)
                  </p>
                  <p className="">
                    Function: The developer can set the maximum transaction
                    percentage of the total token supply. Purpose: The developer
                    can set a maximum transaction limit to prevent market
                    manipulation, especially from large investors who have no
                    partnership with the developer.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    SetSellFee(uint16 tax, uint16 marketing)
                  </p>
                  <p className="">
                    Function: The developer can set the token sale fee to fund
                    development and marketing. Purpose: The developer can set a
                    token sale fee of 5% for development and marketing purposes.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    Transfer(address recipient, uint256 amount)
                  </p>
                  <p className="">
                    Function: Users can send tokens to another account. Purpose:
                    Users can send XYZMER tokens to a friend's wallet address,
                    for example, as a reward for a particular achievement.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    TransferFrom(address sender, address recipient, uint256
                    amount)
                  </p>
                  <p className="">
                    Function: A specific application wants to send tokens from a
                    user to someone else after obtaining the user's permission.
                    Purpose: For example, a crowdfunding platform takes 50
                    XYZMER tokens from a donor after the donor grants
                    permission.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">TransferOwnership</p>
                  <p className="">
                    Function: transferOwnership is a method in a smart contract
                    that allows the contract owner to transfer ownership of the
                    contract to another address. This is typically used when the
                    current owner wishes to hand over control of the contract to
                    another party. Purpose: The purpose of the transferOwnership
                    function is to allow the contract owner to transfer control
                    of the XYZMERCOIN smart contract.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    UpdateTaxPoolWallet(address payable newAddress)
                  </p>
                  <p className="">
                    Function: The developer changes the wallet address that
                    receives the tax from transactions. Purpose: The developer
                    can update the tax wallet address for accounting or security
                    purposes.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">WithdrawBNB(uint256 amount)</p>
                  <p className="">
                    Function: The developer can withdraw a certain amount of BNB
                    from the contract. Purpose: The developer can withdraw BNB
                    from the contract received as donations or payments.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    WithdrawToken(address _tokenContract, uint256 _amount)
                  </p>
                  <p className="">
                    Function: The developer can withdraw other tokens from the
                    contract. Purpose: The developer can withdraw other ERC20
                    tokens received as donations or payments.
                  </p>
                </div>

                
              </div>
            </div>
            <div className="wrap w-full  2xl:flex xl:flex lg:flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-center items-center 2xl:gap-10 xl:gap-10 lg:gap-10 ">
              <PrevBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="XYZMER COIN Whitepaper"
                link="/whitepaper"
              />
              <NextBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="Features & Innovations"
                link="/whitepaper/features"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomic;
