import React from "react";
import Banner1 from "../../../asset/Bannersm.png";

import PrevBtn from "../Component/Button/PrevBtn";

const Conclution = ({ setOnActive, onActive }) => {
  return (
    <div className="Conclutioncontent w-full h-full overflow-y-hidden overflow-x-hidden">
      <div className="wraptitles 2xl:w-[90%] w-[90%] mx-auto 2xl:mx-[100px] ">
        <div className="titles w-full flex mt-[30px]">
          <div className="contains gap-3 2xl:mb-12 2xl:mt-4 xl:mb-12 xl:mt-4 lg:mb-12 lg:mt-4 flex flex-col text-left">
            <img src={Banner1} alt="" />
            <p className="text-white  font-bold 2xl:text-[40px] xl:text-[40px] lg:text-[40px] text-[30px]  mt-[60px] ">
              Conclusion
            </p>

            <p className="text-white w-full 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] mt-5">
              XYZMER COIN offers innovative, transparent, and sustainable
              solutions to the challenges in the creative industry. By combining
              blockchain technology, Initial Offerings, and artificial
              intelligence, XYZMER COIN aspires to lead the future of a fair,
              dynamic, and competitive creative industry.
            </p>

            <p className="text-white w-full 2xl:w-[70%] xl:w-[70%] lg:w-[70%] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[12px] mt-10">
            Join XYZMER COIN and witness the transformation of the creative industry firsthand!
            </p>

            <div className="wrap w-full h-full 2xl:flex xl:flex lg:flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-start mb-10 items-start 2xl:gap-10 xl:gap-10 lg:gap-10  ">
              <PrevBtn
                onActive={onActive}
                setOnActive={setOnActive}
                label="Risk Disclosure"
                link="/whitepaper/risk"
              />
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conclution;
