import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Partnership.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Bnbchain from "../../asset/bnbchain.png";
import Bscdaily from "../../asset/bscdaily.png";
import Metamask from "../../asset/Metamask.png";

import pckswap from "../../asset/Pancakeswap.png";
import cmc from "../../asset/cmc.png";
import cgecko from "../../asset/cgecko.png";
import Trust from "../../asset/Trust Wallet.png";


const Partnership = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
    });
  }, []);

  

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // Settings for screen widths of 1024px and below
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600, // Settings for screen widths of 600px and below
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          initialSlide: 1,
          nextArrow: <div style={{ display: "none" }} />,
          prevArrow: <div style={{ display: "none" }} />,
        },
      },
      {
        breakpoint: 480, // Settings for screen widths of 480px and below
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          nextArrow: <div style={{ display: "none" }} />,
          prevArrow: <div style={{ display: "none" }} />,
        },
      },
    ],
  };

  return (
    <section
      data-aos="fade-up"
      data-aos-delay="600"
      id="partnership"
      className="Partnership w-[90%] 2xl:w-screen justify-center flex 2xl:mt-[100px] xl:mt-[70px] lg:mt-[70px] "
    >
      <div className="wrap-partnership w-screen  2xl:mt-[100px] xl:mt-[100px] lg:mt-[200px] mt-[60px] bg-slate-800 ">
        <Slider {...settings}>
          <div className="slide1">
            <img src={Bnbchain} alt="" />
          </div>
          <div className="slide2">
            <img
              className="2xl:w-[300px] w-[800px] h-auto"
              src={Bscdaily}
              alt=""
            />
          </div>
          
          <div className="slide4">
            <img
              className="2xl:w-[300px] w-[800px] h-auto "
              src={pckswap}
              alt=""
            />
          </div>
          <div className="slide5">
            <img className="2xl:w-[300px] w-[800px] h-auto " src={cmc} alt="" />
          </div>
          <div className="slide6">
            <img
              className="2xl:w-[300px] w-[800px] h-auto"
              src={cgecko}
              alt=""
            />
          </div>
         
          <div className="slide8">
            <img className="w-[400px] h-auto " src={Metamask} alt="" />
          </div>
          <div className="slide8">
            <img className="w-[400px] h-auto" src={Trust} alt="" />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Partnership;
